import { PropsWithChildren, useEffect } from "react";

import { useStore } from "../app/store";

import { FlowColorMode } from "../app/slices/colorModeSlice";
import generatedGitInfo from "../generatedGitInfo.json";

import { Text, Heading, Stack, Image, Code } from "@chakra-ui/react";
import { Button, ButtonGroup, IconButton } from "@chakra-ui/react";
import { Input } from "@chakra-ui/react";

import { TbBinaryTree2 } from "react-icons/tb";
import {
  FaFolderOpen,
  FaSave,
  FaFile,
  FaMoon,
  FaSun,
  FaLayerGroup,
  FaWindowMinimize,
  FaWindowMaximize,
  FaBalanceScaleLeft,
  FaBolt,
} from "react-icons/fa";
import { BiSolidHappy, BiTargetLock } from "react-icons/bi";
import { PiFileAudioFill, PiFileCsvFill, PiPlantFill } from "react-icons/pi";

import { useColorMode } from "@chakra-ui/react";

import { useDisclosure } from "@chakra-ui/react";

import React from "react";
import { Card } from "@chakra-ui/react";
import { Tooltip } from "@chakra-ui/react";
import { Divider } from "@chakra-ui/react";
import ConfirmModal from "./modals/ConfirmModal";
import CompareToolModal from "./modals/CompareToolModal";
import { MdArrowBack } from "react-icons/md";
import FlowGeneratorModal from "./modals/FlowGeneratorModal";
import ImportAudioModal from "./modals/ImportAudioModal";

type MenuBarProps = {
  onLayout: () => void;
};

export default function MenuBar(props: PropsWithChildren<MenuBarProps>) {
  // get filename update function from state
  const {
    filename,
    setFilename,
    setColorMode,
    colorMode,
    collapseAll,
    expandAll,
    onSave,
    onExportCSV,
    onOpen,
    onNew,
    isOpenComplete,
    isEditingFunction,
    functions,
    stopEditingFunction,
  } = useStore((state) => state);

  const { colorMode: uiColorMode, toggleColorMode: toggleUIColorMode } =
    useColorMode();

  // state for the new file confirm modal
  const {
    isOpen: newFileModalIsOpen,
    onOpen: newFileModalOpen,
    onClose: newFileModalClose,
  } = useDisclosure();

  // state for the compare tool modal
  const {
    isOpen: compareModalIsOpen,
    onOpen: compareModalOpen,
    onClose: compareModalClose,
  } = useDisclosure();

  // state for the import audio modal
  const {
    isOpen: importAudioModalIsOpen,
    onOpen: importAudioModalOpen,
    onClose: importAudioModalClose,
  } = useDisclosure();

  // state for the flow generator modal
  const {
    isOpen: flowGeneratorModalIsOpen,
    onOpen: flowGeneratorModalOpen,
    onClose: flowGeneratorModalClose,
  } = useDisclosure();

  useEffect(() => {
    // if the color mode is light, add the .root-light class to the root element
    if (uiColorMode === "light") {
      document.documentElement.classList.add("root-light");
    } else {
      document.documentElement.classList.remove("root-light");
    }
  }, [uiColorMode]);

  return (
    <>
      <ConfirmModal
        isOpen={newFileModalIsOpen}
        onClose={newFileModalClose}
        onSuccess={onNew}
      >
        Proceeding with this action will clear the entire node tree, no progress
        will be saved.
      </ConfirmModal>
      <CompareToolModal
        isOpen={compareModalIsOpen}
        onClose={compareModalClose}
        onSuccess={() => {}}
      />
      <FlowGeneratorModal
        isOpen={flowGeneratorModalIsOpen}
        onClose={flowGeneratorModalClose}
        onSuccess={() => {}}
      />
      <ImportAudioModal
        isOpen={importAudioModalIsOpen}
        onClose={importAudioModalClose}
        onSuccess={() => {}}
      />
      <Stack
        justifyContent="space-between"
        direction="row"
        className="navbar"
        style={{ zIndex: 15, padding: 10, position: "relative" }}
      >
        <Card
          padding={2}
          justifyContent={"center"}
          minWidth={330}
          className="site-info"
        >
          <Stack direction="row" spacing={2}>
            <Image
              src="logo.png"
              alt="Symbal Logo"
              width="150px"
              className={uiColorMode === "dark" ? "invert" : ""}
            />
            <Stack spacing={1} mr={3} justifyContent={"center"}>
              <Heading size="md" userSelect="none">
                Dialogue Tree
              </Heading>
              <Code fontWeight={600}>
                {generatedGitInfo.gitBranch}:{generatedGitInfo.gitCommitHash}
              </Code>
            </Stack>
          </Stack>
          <Text textAlign="center" fontSize="sm" userSelect="none">
            &copy; {new Date().getFullYear()} Symbal AI. All rights reserved.
          </Text>
        </Card>
        <Card padding={2}>
          <Stack direction="row">
            {isEditingFunction !== null ? (
              <>
                <FaBolt color="orange" size={20} />
                <Stack>
                  <Text userSelect="none">
                    <b>
                      Editing "
                      {functions.find((f) => f.id == isEditingFunction)!.name}"
                    </b>
                  </Text>
                  <Button
                    size="sm"
                    leftIcon={<MdArrowBack />}
                    onClick={() => stopEditingFunction()}
                  >
                    Back to main tree
                  </Button>
                </Stack>
                <Divider orientation="vertical" />
              </>
            ) : null}
            <Stack minWidth={130}>
              <ButtonGroup size="sm" isAttached variant="solid">
                <Tooltip label="Highlight edges by target node">
                  <IconButton
                    onClick={() => setColorMode(FlowColorMode.Target)}
                    colorScheme={
                      colorMode == FlowColorMode.Target ? "blue" : "gray"
                    }
                    aria-label="Highlight edges by target node"
                    icon={<BiTargetLock size={20} />}
                  />
                </Tooltip>
                <Tooltip label="Highlight edges and nodes on happy path">
                  <IconButton
                    onClick={() => setColorMode(FlowColorMode.Happy)}
                    colorScheme={
                      colorMode == FlowColorMode.Happy ? "blue" : "gray"
                    }
                    aria-label="Highlight edges by happy path"
                    icon={<BiSolidHappy size={20} />}
                  />
                </Tooltip>
                <Tooltip label="Highlight nodes by group">
                  <IconButton
                    onClick={() => setColorMode(FlowColorMode.Group)}
                    colorScheme={
                      colorMode == FlowColorMode.Group ? "blue" : "gray"
                    }
                    aria-label="Highlight nodes/edges by group"
                    icon={<FaLayerGroup size={20} />}
                  />
                </Tooltip>
                <Tooltip label="Highlight functions by type">
                  <IconButton
                    onClick={() => setColorMode(FlowColorMode.Function)}
                    colorScheme={
                      colorMode == FlowColorMode.Function ? "blue" : "gray"
                    }
                    aria-label="Highlight nodes/edges by group"
                    icon={<FaBolt size={20} />}
                  />
                </Tooltip>
              </ButtonGroup>
              <ButtonGroup size="sm" isAttached>
                <Tooltip label="Collapse nodes for viewing">
                  <IconButton
                    onClick={() => collapseAll()}
                    icon={<FaWindowMinimize />}
                    aria-label="Collapse nodes"
                  />
                </Tooltip>
                <Tooltip label="Expand nodes for editing">
                  <IconButton
                    onClick={() => expandAll()}
                    icon={<FaWindowMaximize />}
                    aria-label="Expand nodes"
                  />
                </Tooltip>
              </ButtonGroup>
            </Stack>
            <Divider orientation="vertical" />
            <Tooltip label="Cleanup Nodes">
              <IconButton
                size="lg"
                colorScheme="teal"
                aria-label="Cleanup Nodes"
                variant="solid"
                icon={<TbBinaryTree2 />}
                onClick={() => props.onLayout()}
              />
            </Tooltip>
            <Tooltip label="Compare Flows">
              <IconButton
                size="lg"
                colorScheme="orange"
                aria-label="Compare"
                variant="solid"
                icon={<FaBalanceScaleLeft />}
                onClick={() => {
                  compareModalOpen();
                }}
              />
            </Tooltip>
            <Tooltip label="Import audio files for nodes">
              <IconButton
                aria-label="Import audio files"
                size="lg"
                colorScheme="red"
                onClick={() => {
                  importAudioModalOpen();
                }}
                icon={<PiFileAudioFill />}
              />
            </Tooltip>
            <Tooltip label="Generate flow from parameters">
              <IconButton
                aria-label="Generate flow"
                size="lg"
                colorScheme="green"
                onClick={() => {
                  flowGeneratorModalOpen();
                }}
                icon={<PiPlantFill />}
              />
            </Tooltip>
            <Input
              focusBorderColor="green.300"
              size="lg"
              data-testid="filename-input"
              style={{ maxWidth: "15rem" }}
              onChange={(e) => {
                // update filename in state
                setFilename(e.target.value);
              }}
              value={filename}
            ></Input>
            <ButtonGroup isAttached variant="solid">
              <Tooltip label="Export as CSV file">
                <IconButton
                  aria-label="Export CSV"
                  size="lg"
                  bgColor="purple.300"
                  colorScheme="purple"
                  onClick={() => {
                    onExportCSV();
                  }}
                  icon={<PiFileCsvFill />}
                />
              </Tooltip>
              <Tooltip label="Save file">
                <IconButton
                  aria-label="Save"
                  size="lg"
                  bgColor="green.300"
                  colorScheme="green"
                  onClick={() => {
                    onSave();
                  }}
                  icon={<FaSave />}
                />
              </Tooltip>
              <Tooltip label="Open file">
                <IconButton
                  isLoading={!isOpenComplete}
                  colorScheme="yellow"
                  size="lg"
                  bgColor="yellow.300"
                  onClick={() => {
                    onOpen();
                  }}
                  icon={<FaFolderOpen />}
                  aria-label="Open"
                />
              </Tooltip>
              <Tooltip label="New file">
                <IconButton
                  colorScheme="red"
                  size="lg"
                  onClick={() => {
                    newFileModalOpen();
                  }}
                  bgColor="red.500"
                  icon={<FaFile />}
                  aria-label="New"
                />
              </Tooltip>
            </ButtonGroup>
            <IconButton
              size="lg"
              variant="solid"
              aria-label="Toggle dark mode"
              colorScheme={uiColorMode === "light" ? "gray" : "yellow"}
              icon={uiColorMode === "light" ? <FaMoon /> : <FaSun />}
              onClick={() => toggleUIColorMode()}
            />
          </Stack>
        </Card>
      </Stack>
    </>
  );
}
