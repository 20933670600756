import React, { useEffect, useState } from "react";

import { Handle, Node, NodeProps, Position } from "reactflow";

import NodeWrapper from "./NodeWrapper";

import { FaBolt, FaWrench } from "react-icons/fa";
import { EditorSlice } from "../app/slices/editorSlice";
import {
  Box,
  Button,
  Card,
  Heading,
  Stack,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { useStore } from "../app/store";
import { ColorModeSlice } from "../app/slices/colorModeSlice";
import { getTextColor, nodeUUIDToColor } from "../app/utility";

type FunctionCallData = {
  functionId: string;
  number: number;
};

type FunctionCall = Node<FunctionCallData>;

const selector = (state: EditorSlice & ColorModeSlice) => ({
  functions: state.functions,
  editFunction: state.editFunction,
  updateNodeColorMode: state.updateNodeColorMode,
});

/**
 * Represents a dialogue node, this is a node that contains some dialogue content (text)
 * @param param0
 * @returns
 */
function FunctionCallComponent({ id, data }: NodeProps<FunctionCallData>) {
  const { functions, editFunction, updateNodeColorMode } = useStore(selector);

  // get function name, and exitNode names
  // from the functionId and the store
  const [functionMeta] = useState<{
    functionName: string;
    functionId: string;
    functionColor: string;
    exitNodes: Node[];
  }>(() => {
    const func = functions.find((f) => f.id === data.functionId);
    if (func !== undefined) {
      const exitNodes = func.nodes.filter((n) => n.type === "function_output");
      return {
        functionName: func.name,
        functionId: func.id,
        functionColor: nodeUUIDToColor(func.id, false),
        exitNodes,
      };
    }
    return {
      functionName: "Function not found",
      functionId: "",
      functionColor: "red",
      exitNodes: [],
    };
  });

  // update current color mode when this node is created
  useEffect(() => {
    updateNodeColorMode();
  }, []);

  const toast = useToast();
  const bg = useColorModeValue("gray.300", "gray.600");

  return (
    <NodeWrapper
      title="Function Call"
      id={`${data.number}` || "Undefined"}
      decoration={<FaBolt color="orange" />}
    >
      <Handle type="target" position={Position.Top} />
      <Stack gap={3} justifyContent="center" mb={3}>
        <Box bgColor={functionMeta.functionColor} borderRadius="md">
          <Heading
            m={2}
            size="lg"
            textAlign="center"
            color={getTextColor(functionMeta.functionColor)}
          >
            {functionMeta.functionName}
          </Heading>
        </Box>
        <Button
          size="md"
          leftIcon={<FaWrench />}
          onClick={() => {
            try {
              editFunction(data.functionId);
            } catch (e) {
              toast({
                title: "Error",
                description: "Could not edit function, does it exist?",
                status: "error",
                duration: 9000,
                isClosable: true,
              });
            }
          }}
        >
          Edit
        </Button>
        <Heading size="sm">Outputs</Heading>
      </Stack>
      <Stack gap={3}>
        <Stack direction="row" gap={8} className="response-container">
          {
            // loop through all responses and display them as badges
            functionMeta.exitNodes.length > 0 ? (
              functionMeta.exitNodes.map((exitNode: Node, index: number) => {
                return (
                  <Card
                    backgroundColor={bg}
                    variant="outline"
                    key={index}
                    className="response-item"
                    style={{ maxWidth: "15rem" }}
                  >
                    <span style={{ textAlign: "center" }}>
                      {exitNode.data.name}
                    </span>
                    <Handle
                      aria-label="Function handle output"
                      id={`${id}_out_${exitNode.id}`}
                      className="response-handle"
                      type="source"
                      position={Position.Bottom}
                    />
                  </Card>
                );
              })
            ) : (
              <></>
            )
          }
        </Stack>
      </Stack>
    </NodeWrapper>
  );
}

export { FunctionCallComponent, type FunctionCall };
