import { StateCreator } from "zustand";
import { FlowSlice } from "./flowSlice";

import { Edge, Node } from "reactflow";

import { v4 as uuidv4 } from "uuid";
import { DialogueNode, DialogueResponse } from "../../nodes/DialogueNode";
import { DialogueEntry } from "../../nodes/DialogueEntry";
import { EditorSlice, NodeFunction, NodeGroup } from "./editorSlice";
import { ColorModeSlice, FlowColorMode } from "./colorModeSlice";
import { Buffer } from "buffer";

interface InputOutputSlice {
  isOpenComplete: boolean;

  onSave: () => void;
  onExportCSV: () => void;
  onOpen: () => void;
  onNew: () => void;
}

type NodeFlow = {
  nodes: Node[];
  edges: Edge[];
  groups?: NodeGroup[];
  functions?: NodeFunction[];
  viewport?: { x: number; y: number; zoom: number };
};

/**
 * Type for old equinox dialogue nodes.
 * For backwards compatibility.
 */
type EquinoxDialgoueNodeData = {
  id: string;
  responses: string[];
  npc_text: string;
};

/**
 * Allows old equinox dialogue flows to be upgraded to the new format.
 * @param nodes an array of nodes from the JSON
 * @param edges an array of edges from the JSON
 */
const upgradeOldFlow = (
  nodes: Node<EquinoxDialgoueNodeData>[],
  edges: Edge[]
) => {
  // first, convert all the IDs to UUIDs

  // create a map to store the old node ids and their new uuids
  const nodeIDMap = new Map<string, string>();

  // a map with key = node id, value = array of response uuids
  const responseIDMap = new Map<string, string[]>();

  // run regex/replace on all node ids, edge targets, and edge sources
  const updatedNodes = nodes.map((node) => {
    const updatedNode: DialogueNode = {
      ...node,
      data: {
        content: node.data.npc_text,
        responses: [],
      },
    };
    const oldID = node.id;
    const newID = uuidv4();
    nodeIDMap.set(oldID, newID);
    updatedNode.id = newID;

    // update node types
    // nodes can either be "dialogue_node" or "dialogue_entry"
    // the old values are "dialogue_event" and "dialogue_entry", ignore everything else
    if (node.type === "dialogue_event") {
      updatedNode.type = "dialogue_node";
    } else if (node.type === "dialogue_entry") {
      // must be a dialogue entry node
      // @ts-expect-error - we know that this is a dialogue entry node
      updatedNode.data = {};
      return updatedNode as DialogueEntry;
    } else {
      // not a dialogue node or entry node, ignore it
      return updatedNode;
    }

    // update the responses
    const newIDs: string[] = [];
    const updatedResponses = node.data.responses.map((response, index) => {
      const newID = uuidv4();
      // set at index
      newIDs[index] = newID;
      return {
        id: newID,
        content: response,
        happy: false,
      } as DialogueResponse;
    });
    responseIDMap.set(newID, newIDs);

    updatedNode.data.responses = updatedResponses;

    return updatedNode;
  });

  // update the edge targets and sources
  edges.forEach((edge) => {
    edge.target = nodeIDMap.get(edge.target) || edge.target;
    edge.source = nodeIDMap.get(edge.source) || edge.source;

    // now convert the handles
    // if a handle is "flow" make it null
    // if a handle starts with "response_", get the source/target node id
    // and the response index, and get the response uuid from the map
    // and replace the handle with <node_uuid>_res_<response_uuid>
    if (edge.sourceHandle === "flow") {
      edge.sourceHandle = null;
    } else if (edge.sourceHandle?.startsWith("response_")) {
      const nodeID: string = edge.source;
      const responseIndex: number = parseInt(edge.sourceHandle.split("_")[1]);
      const responseUUID = responseIDMap.get(nodeID)?.[responseIndex];
      // remove edge if we can't find the response
      if (!responseUUID) {
        edges = edges.filter((e) => e.id !== edge.id);
        return;
      }
      edge.sourceHandle = `${nodeID}_res_${responseUUID}`;
      if (edge.targetHandle === "flow") {
        edge.targetHandle = edge.target;
      }
    }

    // update edge id
    edge.id = `reactflow__edge-${edge.source}${edge.sourceHandle || ""}-${
      edge.target
    }${edge.targetHandle || ""}`;
  });

  return {
    nodes: updatedNodes,
    edges: edges,
  };
};

const decodeAudioData = (audio: string) => {
  // Convert the base64 string to a buffer.
  const buffer = Buffer.from(audio, "base64");

  // Return the buffer.
  return buffer.buffer;
};

const encodeAudioData = (audio: ArrayBuffer) => {
  // Convert the ArrayBuffer to a Buffer.
  const audioBuffer = Buffer.from(audio);
  // Convert the buffer to a base64 string.
  const base64String = audioBuffer.toString("base64");

  // Return the base64 string.
  return base64String;
};

/**
 * Parses a JSON string into a flow.
 * @param json the JSON string to parse
 */
const parseJSONToFlow = (json: string, filename: string): Promise<NodeFlow> => {
  let flow = JSON.parse(json);

  if (flow && (flow as NodeFlow) !== undefined) {
    // cast nodes and edges to the correct types
    flow = flow as NodeFlow;

    // check if file extension is .dlg.src
    // if so, convert it to the new format
    if (filename.endsWith(".dlg.src")) {
      flow = upgradeOldFlow(flow.nodes, flow.edges);
      // replace extension with .json
      filename = filename.replace(".dlg.src", ".json");
    }

    // remove "dragHandle" from nodes
    flow.nodes.forEach((node: Node) => {
      delete node.dragHandle;
    });

    // decode audio data as ArrayBuffer
    flow.nodes.forEach((node: Node) => {
      if (node.data.audio_encoded) {
        try {
          node.data = {
            ...node.data,
            audio: decodeAudioData(node.data.audio_encoded),
          };
        } catch (e) {
          console.error("Error encoding audio data", e);
        }
      }
    });

    return Promise.resolve(flow);
  } else {
    return Promise.reject("Invalid flow JSON file");
  }
};

/**
 * Converts a flow to a JSON string.
 * @param flow the flow to convert
 * @returns the JSON string
 */
const stringifyFlowToJSON = (flow: NodeFlow) => {
  // encode audio data as string
  flow.nodes.forEach((node: Node) => {
    if (node.data.audio) {
      try {
        node.data.audio_encoded = encodeAudioData(node.data.audio);
      } catch (e) {
        console.error("Error decoding audio data", e);
      }
    }
  });

  // remove useless properties
  flow.nodes.forEach((node: Node) => {
    delete node.style;
    delete node.dragHandle;
  });
  flow.edges.forEach((edge: Edge) => {
    delete edge.style;
    delete edge.animated;
  });

  const json = JSON.stringify(flow);
  return json;
};

const createInputOutputSlice: StateCreator<
  InputOutputSlice & FlowSlice & EditorSlice & ColorModeSlice,
  [],
  [],
  InputOutputSlice
> = (set, get) => ({
  isOpenComplete: true,

  /**
   * This function is called when the user clicks the save button.
   * @returns void
   */
  onSave: () => {
    // return if we don't have a flow instance
    if (!get().flowInstance) return;

    // if we're currently editing a function, stop editing it
    if (get().isEditingFunction) {
      get().stopEditingFunction();
    }

    const flow = {
      nodes: get().nodes,
      edges: get().edges,
      groups: get().nodeGroups,
      functions: get().functions,
    };

    const json = stringifyFlowToJSON(flow);
    const blob = new Blob([json], {
      type: "application/json",
    });

    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", get().filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
  },

  onExportCSV: () => {
    // if we're currently editing a function, stop editing it
    if (get().isEditingFunction) {
      get().stopEditingFunction();
    }

    let csv = "";

    // create header with nodeid and nodetext
    csv += "nodeNumber,nodeID,nodeText\n";

    // create rows with nodeid and nodetext
    get().nodes.forEach((node) => {
      csv += `${node.data.number},${node.id},"${node.data.content || ""}"\n`;
    });

    // add nodes in functions as well
    get().functions.forEach((func) => {
      func.nodes.forEach((node) => {
        csv += `${node.data.number},${node.id},"${node.data.content || ""}"\n`;
      });
    });

    // save as csv
    const blob = new Blob([csv], {
      type: "text/csv",
    });

    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");

    // replace extension with .csv
    const filename = get().filename.replace(".json", ".csv");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
  },
  /**
   * This function is called when the user clicks the open button.
   */
  onOpen: () => {
    // show file open dialog
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "application/json";
    input.onchange = async (event) => {
      // set as not done loading
      set({ isOpenComplete: false });

      const file = (event.target as HTMLInputElement).files![0];
      const text = await file.text();

      // try to parse the JSON
      const flow = await parseJSONToFlow(text, file.name).catch((error) => {
        alert("Error parsing JSON: " + error);
        return;
      });

      if (!flow) return;

      // if we're currently editing a function, stop editing it
      if (get().isEditingFunction) {
        get().stopEditingFunction();
      }

      await get().onNew();

      // number any nodes that don't have a number
      flow.nodes = flow.nodes.map((node: Node) => {
        get().updateNodeNumber(node);
        return node;
      });

      // set nodes edges and the filename
      set({
        nodeGroups: flow.groups || [],
        nodes: flow.nodes || [],
        edges: flow.edges || [],
        functions: flow.functions || [],
        filename: file.name,
      });

      // update max node number
      get().updateNodeNumberMax();

      // add included functions
      get().addIncludedFunctions();

      // reset color mode
      get().setColorMode(FlowColorMode.Target);

      // set as done loading
      set({ isOpenComplete: true });

      // fit view to nodes
      get().centerView();
    };
    input.click();
    // remove the input element
    input.remove();
  },

  /**
   * This function is called when the user clicks the new button.
   */
  onNew: () => {
    set({
      nodes: [],
      edges: [],
      filename: "Untitled.json",
      nodeGroups: [],
      colorMode: FlowColorMode.Target,
      nodeNumberMax: 0,
      functions: [],
      isLayoutDirty: false,
    });

    // add included functions
    get().addIncludedFunctions();
  },
});

export {
  createInputOutputSlice,
  type InputOutputSlice,
  type NodeFlow,
  parseJSONToFlow,
  stringifyFlowToJSON,
};
