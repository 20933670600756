import React from "react";

import "./App.css";
import { ReactFlowProvider } from "reactflow";
import Flow from "./Flow";

import theme from "./theme";

import { ChakraProvider } from "@chakra-ui/react";

function App() {
  return (
    <ChakraProvider theme={theme}>
      <ReactFlowProvider>
        <Flow />
      </ReactFlowProvider>
    </ChakraProvider>
  );
}

export default App;
