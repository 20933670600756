import { StateCreator } from "zustand";
import { FlowSlice } from "./flowSlice";

import { Edge, Node } from "reactflow";

import { v4 as uuidv4 } from "uuid";
import { nodeUUIDToColor } from "../utility";

interface CopyPasteSlice {
  mousePosition: { x: number; y: number };
  onMouseMove: (event: MouseEvent) => void;
  onCopy: () => void;
  onPaste: () => void;
}

const createCopyPasteSlice: StateCreator<
  CopyPasteSlice & FlowSlice,
  [],
  [],
  CopyPasteSlice
> = (set, get) => ({
  mousePosition: { x: 0, y: 0 },

  /**
   * Sets the mouse position
   * @param mousePosition the mouse position to set
   */
  onMouseMove: (event: MouseEvent) => {
    set({
      mousePosition: {
        x: event.clientX,
        y: event.clientY,
      },
    });
  },

  /**
   * This function is called when the user copies nodes or edges
   * @returns void
   */
  onCopy: () => {
    // return if we don't have a flow instance or if there is nothing selected
    if (!get().flowInstance) return;
    if (get().selectedNodes.length === 0 && get().selectedEdges.length === 0)
      return;

    const flow = {
      nodes: get().selectedNodes,
      edges: get().selectedEdges,
    };

    // convert to json
    const json = JSON.stringify(flow);

    // check if we're on firefox, firefox doesn't
    // let us read from the clipboard so we need
    // to use the local storage hack
    const isFirefox = navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
    if (isFirefox) {
      // set local storage item
      localStorage.setItem("reactflow__clipboard", json);
      return;
    }

    // copy to clipboard
    navigator.clipboard.writeText(json);
  },

  /**
   * This function is called when the user pastes nodes or edges
   * @returns void
   */
  onPaste: async () => {
    // return if we don't have a flow instance
    if (!get().flowInstance) return;

    // check if we're on firefox, if so, read from local storage
    const isFirefox = navigator.userAgent.toLowerCase().indexOf("firefox") > -1;

    let json = "";
    if (isFirefox) {
      // get local storage item
      json = localStorage.getItem("reactflow__clipboard") || "";
    } else {
      // read from clipboard
      json = await navigator.clipboard.readText();
    }

    // parse json string
    let flow = null;
    try {
      flow = JSON.parse(json);
    } catch (e) {
      // invalid json
      return;
    }

    // return if we didn't get a valid flow
    if (!flow) return;
    if (!flow.nodes) return;

    // the new nodes need new ids, but we need to update any edges that reference the old ids
    const idMap = new Map<string, string>();
    flow.nodes.forEach((node: Node) => {
      const newId = uuidv4();
      idMap.set(node.id, newId);
      node.id = newId;
    });

    // update edges and filter out any edges that reference nodes that don't exist in our idMap
    const newEdges = flow.edges
      .filter((edge: Edge) => {
        // make sure source and target exist in idMap
        if (!idMap.has(edge.source) || !idMap.has(edge.target)) {
          return false;
        } else {
          return true;
        }
      })
      .map((edge: Edge) => {
        const newEdge = { ...edge };
        // remap source and target UUIDs
        // @ts-expect-error - we know that idMap has the keys we're looking for
        newEdge.source = idMap.get(edge.source);
        // @ts-expect-error - we know that idMap has the keys we're looking for
        newEdge.target = idMap.get(edge.target);

        // update edge color
        newEdge.style = {
          stroke: nodeUUIDToColor(newEdge.target),
        };

        // create new edge ID
        newEdge.id = `reactflow__edge-${newEdge.source}${
          newEdge.sourceHandle || ""
        }-${newEdge.target}${newEdge.targetHandle || ""}`;

        // remap handles if we have them
        if (newEdge.sourceHandle) {
          // split by '_' and replace first element with new id
          const parts = newEdge.sourceHandle.split("_");
          parts[0] = newEdge.source;
          newEdge.sourceHandle = parts.join("_");
        }
        if (newEdge.targetHandle) {
          // split by '_' and replace first element with new id
          const parts = newEdge.targetHandle.split("_");
          parts[0] = newEdge.target;
          newEdge.targetHandle = parts.join("_");
        }

        return newEdge;
      });

    // offset nodes so that they are centered at the mouse cursor position
    const mousePosition = get().flowInstance!.screenToFlowPosition({
      x: get().mousePosition.x,
      y: get().mousePosition.y,
    });

    // get the average position of all nodes in the selection flow
    const averagePosition = {
      x: 0,
      y: 0,
    };
    flow.nodes.forEach((node: Node) => {
      averagePosition.x += node.position.x;
      averagePosition.y += node.position.y;
    });

    averagePosition.x /= flow.nodes.length;
    averagePosition.y /= flow.nodes.length;

    // create new node objects
    const newNodes = flow.nodes.map((node: Node) => {
      const newNode = { ...node };
      // invalidate old node number
      delete newNode.data.number;
      // assign new node number
      get().updateNodeNumber(newNode);
      newNode.position = {
        x: node.position.x - averagePosition.x + mousePosition.x,
        y: node.position.y - averagePosition.y + mousePosition.y,
      };
      return newNode;
    });

    if (flow) {
      // append to nodes
      set({
        nodes: [...get().nodes, ...newNodes],
        edges: [...get().edges, ...newEdges],
      });
    }
  },
});

export { type CopyPasteSlice, createCopyPasteSlice };
