import { useEffect, useState } from "react";
import {
  Popover,
  PopoverTrigger,
  Button,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Center,
  SimpleGrid,
  Input,
  Portal,
} from "@chakra-ui/react";

type ColorPickerProps = {
  color: string;
  onChange: (color: string) => void;
};

/**
 * This component renders a color picker, which is a button that opens a popover with a grid of colors.
 * @param props The props for this component
 * @returns The color picker component
 */
export default function ColorPicker(props: ColorPickerProps) {
  const [color, setColor] = useState(props.color);

  const colors = [
    "gray.500",
    "red.500",
    "green.500",
    "blue.500",
    "blue.800",
    "yellow.500",
    "orange.500",
    "purple.500",
    "pink.500",
  ];

  const colorsHex = [
    "#718096",
    "#f56565",
    "#48bb78",
    "#4299e1",
    "#2a4365",
    "#ecc94b",
    "#ed8936",
    "#9f7aea",
    "#f687b3",
  ];

  // call onChange when the color changes
  useEffect(() => {
    props.onChange(color);
  }, [color, props]);

  // change color when props.color changes
  useEffect(() => {
    setColor(props.color);
  }, [props.color]);

  return (
    <Popover variant="picker">
      <PopoverTrigger>
        <Button
          aria-label={color}
          background={color}
          padding={0}
          maxWidth="md"
          borderRadius={3}
        ></Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent width="170px">
          <PopoverArrow bg={color} />
          <PopoverCloseButton color="white" />
          <PopoverHeader
            height="100px"
            backgroundColor={color}
            borderTopLeftRadius={5}
            borderTopRightRadius={5}
            color="white"
          >
            <Center height="100%">{color}</Center>
          </PopoverHeader>
          <PopoverBody height="120px">
            <SimpleGrid columns={5} spacing={2}>
              {colors.map((c) => (
                <Button
                  key={c}
                  aria-label={c}
                  background={c}
                  height="22px"
                  width="22px"
                  padding={0}
                  minWidth="unset"
                  borderRadius={3}
                  _hover={{ background: c }}
                  onClick={() => {
                    setColor(colorsHex[colors.indexOf(c)]);
                  }}
                ></Button>
              ))}
            </SimpleGrid>
            <Input
              borderRadius={3}
              marginTop={3}
              placeholder="red.100"
              size="sm"
              value={color}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setColor(e.target.value);
              }}
            />
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}
