import React from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Button,
  Stack,
  Input,
  useToast,
} from "@chakra-ui/react";
import { useStore } from "../../app/store";
import { Node } from "reactflow";

type ImportAudioModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
};

const ImportAudioModal = (props: ImportAudioModalProps) => {
  const finalRef = React.useRef(null);
  const { setNodeAudio, getNodeFromNumber } = useStore((state) => state);

  const [audioFiles, setAudioFiles] = React.useState<FileList | null>(null);
  const toast = useToast();

  const handleImport = () => {
    // read audio files
    let error = "";
    if (audioFiles) {
      for (let i = 0; i < audioFiles.length; i++) {
        const file = audioFiles[i];
        const reader = new FileReader();
        reader.onload = (e) => {
          if (!e.target) {
            error = "Error reading audio file(s)";
            return;
          }
          const audioData = e.target!.result;
          // get node number from file name
          const nodeNumber = parseInt(file.name.split("node")[1]);
          if (!audioData || isNaN(nodeNumber)) {
            error = "Error: Audio data or file name is invalid";
            return;
          }

          // get node from number
          getNodeFromNumber(nodeNumber).then((node: Node | null) => {
            if (!node) {
              error = `Error: Node ${nodeNumber} not found for audio file`;
              return;
            }
            // set audio data
            setNodeAudio(node.id, audioData as ArrayBuffer);
          });
        };
        reader.onerror = () => {
          error = "Error: Could not read audio file(s)";
          return;
        };
        reader.readAsArrayBuffer(file);
      }
    } else {
      error = "Error: No audio files selected";
    }

    if (error.length > 0) {
      // show error toast
      toast({
        title: error,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Modal
        finalFocusRef={finalRef}
        isOpen={props.isOpen}
        onClose={props.onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Import Audio for Nodes</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Please select audio files to import. They must be of the format
            nodeN where N is the node number.
            <Stack mt={4}>
              <Input
                type="file"
                id="audio"
                name="audio"
                accept="audio/*"
                multiple
                onChange={(e) => {
                  setAudioFiles(e.target.files);
                }}
              />
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="green"
              mr={3}
              onClick={() => {
                props.onClose();
                handleImport();
              }}
            >
              Confirm
            </Button>
            <Button colorScheme="red" onClick={props.onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ImportAudioModal;
