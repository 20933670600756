import React from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Button,
  Textarea,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { JobFlowParameters } from "../../app/slices/flowGenerator";
import { useStore } from "../../app/store";

type FlowGeneratorModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
};

const FlowGeneratorModal = (props: FlowGeneratorModalProps) => {
  const finalRef = React.useRef(null);
  const toast = useToast();

  const { generateFlow } = useStore((state) => state);
  const [jobParams, setJobParams] = React.useState<JobFlowParameters>({
    candidateName: "",
    jobTitle: "",
    jobPostSiteName: "",
    jobDescription: "",
    jobLocation: "",
    jobRequirements: [],
  });

  return (
    <>
      <Modal
        finalFocusRef={finalRef}
        isOpen={props.isOpen}
        onClose={props.onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Generate Flow From Parameters</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Please enter the job parameters for the flow you would like to
            generate.
            <Stack mt={4}>
              <Textarea
                onChange={(e) => {
                  // update job params
                  let obj = {};
                  try {
                    obj = JSON.parse(e.target.value);
                  } catch (e) {
                    toast({
                      title: "Error",
                      description: "Invalid JSON",
                      status: "error",
                      duration: 9000,
                      isClosable: true,
                    });
                  }
                  setJobParams(obj as JobFlowParameters);
                }}
                placeholder="Paste JSON here..."
              ></Textarea>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="green"
              mr={3}
              onClick={() => {
                generateFlow(jobParams);
                props.onClose();
              }}
            >
              Confirm
            </Button>
            <Button colorScheme="red" onClick={props.onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default FlowGeneratorModal;
