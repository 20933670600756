import { StateCreator } from "zustand";
import { FlowSlice } from "./flowSlice";

import { nodeUUIDToColor } from "../utility";
import { DialogueResponse } from "../../nodes/DialogueNode";
import { EditorSlice } from "./editorSlice";

enum FlowColorMode {
  Target = "target",
  Happy = "happy",
  Group = "group",
  Function = "function",
}

interface ColorModeSlice {
  colorMode: FlowColorMode;
  setColorMode: (colorMode: FlowColorMode) => void;
  setNodeColor: (nodeId: string, color: string) => void;
  setEdgeColor: (edgeId: string, color: string) => void;
  updateNodeColorMode: (nodeId?: string) => void;
  updateEdgeColorMode: (edgeId?: string) => void;
}

const createColorModeSlice: StateCreator<
  ColorModeSlice & FlowSlice & EditorSlice,
  [],
  [],
  ColorModeSlice
> = (set, get) => ({
  colorMode: FlowColorMode.Target,

  /**
   * Sets the node color
   * @param nodeId the id of the node to set the color of
   * @param color the color to set the node to
   */
  setNodeColor: (nodeId: string, color: string) => {
    // set the target node color
    set({
      nodes: get().nodes.map((node) => {
        if (node.id === nodeId) {
          node.style = { ...node.style, borderColor: color };
        }
        return node;
      }),
    });
  },

  /**
   * Sets the edge color
   * @param edgeId the id of the edge to set the color of
   * @param color the color to set the edge to
   */
  setEdgeColor: (edgeId: string, color: string) => {
    // set the target node color to green
    set({
      edges: get().edges.map((edge) => {
        if (edge.id === edgeId) {
          edge.style = { ...edge.style, stroke: color };
        }
        return edge;
      }),
    });
  },

  /**
   * Updates the node colors
   * @param nodeId currently unused
   */
  updateNodeColorMode: (nodeId?: string) => {
    // if we have an id, set the color of the node with that id
    if (nodeId) {
      const node = get().nodes.find((node) => node.id === nodeId);
      if (!node) return;

      // we can only do this for the group color mode
      if (get().colorMode === FlowColorMode.Group) {
        get().setNodeColor(
          node.id,
          get().nodeGroups.find((nodeGroup) => {
            return nodeGroup.id === node.data.group;
          })?.color || "inherit"
        );
        return;
      }
    }

    // run setColorMode with the current color mode
    get().setColorMode(get().colorMode);
  },

  /**
   * Updates the edge colors
   */
  updateEdgeColorMode: () => {
    // run setColorMode with the current color mode
    get().setColorMode(get().colorMode);
  },

  /**
   * Sets the color mode
   * @param colorMode the color mode to set
   */
  setColorMode: (colorMode: FlowColorMode) => {
    // switch statement for new color mode
    switch (colorMode) {
      case FlowColorMode.Target: {
        // set new color mode
        set({ colorMode: colorMode });

        // set edge colors
        get().edges.forEach((edge) => {
          get().setEdgeColor(edge.id, nodeUUIDToColor(edge.target));
        });

        // set node colors
        get().nodes.forEach((node) => {
          get().setNodeColor(node.id, "inherit");
        });
        break;
      }
      case FlowColorMode.Happy: {
        // set new color mode
        set({ colorMode: colorMode });

        // count how many incoming happy edges a node has, if it has more than 1
        // set the node color to green
        const incomingHappyEdges: Map<string, number> = new Map();

        // set new colors
        // find edges that have a response marked as happy
        // and set their color to green, also the target node of the edge
        get().edges.forEach((edge) => {
          // get the source node and the response from the edge
          const sourceNode = get().nodes.find(
            (node) => node.id === edge.source
          );

          if (!sourceNode) return;
          if (sourceNode.type !== "dialogue_node") {
            get().setEdgeColor(edge.id, "grey");
            return;
          }
          const response: DialogueResponse = sourceNode.data.responses.find(
            (response: DialogueResponse) =>
              response.id === edge.sourceHandle?.split("_")[2]
          );

          if (!response) return;

          // check if the response is happy
          if (response.happy) {
            get().setEdgeColor(edge.id, "lightgreen");
            get().setNodeColor(edge.target, "lightgreen");
            incomingHappyEdges.set(
              edge.target,
              1 + (incomingHappyEdges.get(edge.target) || 1)
            );
          } else {
            get().setEdgeColor(edge.id, "grey");
          }
        });

        // set node colors
        get().nodes.forEach((node) => {
          if (
            incomingHappyEdges.get(node.id) &&
            incomingHappyEdges.get(node.id)! > 0
          ) {
            get().setNodeColor(node.id, "lightgreen");
          } else {
            get().setNodeColor(node.id, "inherit");
          }
        });

        break;
      }
      case FlowColorMode.Group: {
        // set new color mode
        set({ colorMode: colorMode });

        // set edge colors
        get().edges.forEach((edge) => {
          get().setEdgeColor(edge.id, "grey");
        });

        // set node colors
        get().nodes.forEach((node) => {
          get().setNodeColor(
            node.id,
            get().nodeGroups.find((nodeGroup) => {
              return nodeGroup.id === node.data.group;
            })?.color || "inherit"
          );
        });

        break;
      }
      case FlowColorMode.Function: {
        // set new color mode
        set({ colorMode: colorMode });

        // set edge colors
        get().edges.forEach((edge) => {
          get().setEdgeColor(edge.id, "grey");
        });

        // set node colors
        get().nodes.forEach((node) => {
          if (node.type === "function_call") {
            const functionDefinitionId = get().functions.find(
              (func) => func.id === node.data.functionId
            )?.id;

            get().setNodeColor(
              node.id,
              nodeUUIDToColor(functionDefinitionId || "", false)
            );
          } else {
            get().setNodeColor(node.id, "inherit");
          }
        });

        break;
      }
    }

    set({ colorMode: colorMode });
  },
});

export { type ColorModeSlice, FlowColorMode, createColorModeSlice };
