import { StateCreator } from "zustand";
import { FlowSlice } from "./flowSlice";

import { Edge, Node } from "reactflow";

import { v4 as uuidv4 } from "uuid";
import { ColorModeSlice } from "./colorModeSlice";
import { NodeFlow } from "./inputOutputSlice";

type NodeGroup = {
  id: string;
  name: string;
  color: string;
};

type NodeFunction = {
  id: string;
  name: string;
  nodes: Node[];
  edges: Edge[];
  exitNodes: string[];
};

interface EditorSlice {
  nodeGroups: NodeGroup[];

  // list of functions
  functions: NodeFunction[];
  // the main tree flow data (for restoring the main tree)
  mainTree: NodeFlow;
  isEditingFunction: string | null;
  addFunction: (name: string) => void;
  removeFunction: (id: string) => void;
  editFunction: (id: string) => void;
  stopEditingFunction: () => void;
  setOutputNodeName: (id: string, name: string) => void;

  collapsedNodes: string[];
  addNodeGroup: (color: string, name: string) => void;
  removeNodeGroup: (id: string) => void;
  setNodeGroup: (nodeId: string, groupId: string) => void;
  collapseAll: () => void;
  expandAll: () => void;
  toggleNodeCollapsed: (id: string) => void;

  // flags for marking if the layout
  // is currently not aligned with the layout engine's layout
  isLayoutDirty: boolean;
  setLayoutDirty: () => void;
}

const createEditorSlice: StateCreator<
  EditorSlice & FlowSlice & ColorModeSlice,
  [],
  [],
  EditorSlice
> = (set, get) => ({
  nodeGroups: [],
  collapsedNodes: [],
  functions: [],
  isEditingFunction: null,
  mainTree: { nodes: [], edges: [] },
  isLayoutDirty: false,

  /**
   * Marks the layout as dirty
   */
  setLayoutDirty: () => {
    set({ isLayoutDirty: true });
  },

  /**
   * Sets the output node name
   * @param id the id of the node
   * @param name the name to set
   */
  setOutputNodeName: (id: string, name: string) => {
    set({
      nodes: get().nodes.map((node) => {
        if (node.id === id) {
          node.data = { ...node.data, name: name };
        }
        return node;
      }),
    });
  },

  /**
   * Adds a function to the editor
   * @param name the name of the function
   */
  addFunction: (name: string) => {
    const id = uuidv4();
    set({
      functions: [
        ...get().functions,
        {
          id: id,
          name,
          nodes: [],
          edges: [],
          exitNodes: [],
        },
      ],
    });

    // edit function after adding it
    // this function throws an error if the function doesn't exist
    // but we just added it, so we know it exists
    get().editFunction(id);

    // add entry node
    get().addNode("dialogue_entry", { x: 0, y: 0 });
  },

  /**
   * Removes a function from the editor
   * @param id the id of the function to remove
   */
  removeFunction: (id: string) => {
    // if this is the currently editing function, stop editing it
    if (get().isEditingFunction === id) {
      get().stopEditingFunction();
    }

    set({
      functions: get().functions.filter((func) => func.id !== id),
    });
  },

  /**
   * Enters edit mode for a function
   * @param id the id of the function to edit
   */
  editFunction: (id: string) => {
    // check that the function exists, if it doesn't throw an error
    if (!get().functions.some((func) => func.id === id)) {
      throw new Error(`Function with id ${id} does not exist`);
    }

    // set currently editing function
    set({ isEditingFunction: id });

    // save the current flow as the main tree
    set({
      mainTree: {
        nodes: get().nodes,
        edges: get().edges,
        viewport: get().flowInstance?.getViewport(),
      },
    });

    // set the flow to the function
    const func = get().functions.find((func) => func.id === id);
    if (func) {
      set({
        nodes: func.nodes,
        edges: func.edges,
      });
    }

    // center view
    get().centerView();
  },

  /**
   * Stops editing the current function
   */
  stopEditingFunction: () => {
    // store current function flow in the function object
    set({
      functions: get().functions.map((func) => {
        if (func.id === get().isEditingFunction) {
          return {
            ...func,
            nodes: get().nodes,
            edges: get().edges,
          };
        }
        return func;
      }),
    });

    // set currently editing function to null
    set({ isEditingFunction: null });

    // set the flow to the main tree
    set({
      nodes: get().mainTree.nodes,
      edges: get().mainTree.edges,
    });

    // restore viewport from main tree
    if (get().flowInstance) {
      if (get().mainTree.viewport) {
        get().flowInstance!.setViewport(get().mainTree.viewport!);
      } else {
        get().flowInstance!.fitView();
      }
    }
  },

  /**
   * Adds a node group to the editor
   * @param color the color of the node group
   * @param name the name of the node group
   */
  addNodeGroup: (color: string, name: string) => {
    set({
      nodeGroups: [
        ...get().nodeGroups,
        {
          id: uuidv4(),
          name,
          color,
        },
      ],
    });
  },

  /**
   * Removes a node group from the editor
   * @param id the id of the node group to remove
   */
  removeNodeGroup: (id: string) => {
    set({
      nodeGroups: get().nodeGroups.filter((nodeGroup) => nodeGroup.id !== id),
    });
  },

  /**
   * Sets the node group of a node
   * @param nodeId the id of the node to set the group of
   * @param groupId the id of the group to set the node to
   */
  setNodeGroup: (nodeId: string, groupId: string) => {
    set({
      nodes: get().nodes.map((node) => {
        if (node.id === nodeId) {
          node.data = { ...node.data, group: groupId };
        }
        return node;
      }),
    });

    // update node color mode
    get().updateNodeColorMode(nodeId);
  },

  /**
   * Collapses all nodes in the editor
   */
  collapseAll: () => {
    set({
      collapsedNodes: get().nodes.map((node) => node.id),
    });
  },

  /**
   * Expands all nodes in the editor
   */
  expandAll: () => {
    set({
      collapsedNodes: [],
    });
  },

  /**
   * Toggles the collapsed state of a node
   * @param id the id of the node to toggle
   */
  toggleNodeCollapsed: (id: string) => {
    // if the node id is part of the collapsed nodes, remove it
    // otherwise, add it
    set({
      collapsedNodes: get().collapsedNodes.includes(id)
        ? get().collapsedNodes.filter((nodeId) => nodeId !== id)
        : [...get().collapsedNodes, id],
    });
  },
});

export {
  type EditorSlice,
  type NodeGroup,
  type NodeFunction,
  createEditorSlice,
};
