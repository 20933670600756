import { StateCreator } from "zustand";

import {
  Connection,
  Edge,
  EdgeChange,
  Node,
  NodeChange,
  addEdge,
  OnNodesChange,
  OnEdgesChange,
  OnConnect,
  applyNodeChanges,
  applyEdgeChanges,
  ReactFlowInstance,
  OnSelectionChangeFunc,
  NodeAddChange,
  XYPosition,
  NodeRemoveChange,
  Rect,
} from "reactflow";

import { v4 as uuidv4 } from "uuid";
import { ColorModeSlice } from "./colorModeSlice";
import { EditorSlice } from "./editorSlice";
import { supervisorFunction } from "../includedFunctions/supervisor";

interface FlowSlice {
  nodes: Node[];
  edges: Edge[];
  selectedNodes: Node[];
  selectedEdges: Edge[];
  flowInstance?: ReactFlowInstance;
  filename: string;
  nodeNumberMax: number;

  setFilename: (filename: string) => void;

  onSelectionChange: OnSelectionChangeFunc;

  onInit: (instance: ReactFlowInstance) => void;
  onNodesChange: OnNodesChange;
  onEdgesChange: OnEdgesChange;
  onConnect: OnConnect;
  onDragOver: (event: React.DragEvent<HTMLDivElement>) => void;
  onDrop: (event: React.DragEvent<HTMLDivElement>) => void;
  setNodesAndEdges: (nodes: Node[], edges: Edge[]) => void;
  updateNodeNumber: (node: Node, replace?: boolean) => void;
  updateNodeNumberMax: () => void;
  getNodeFromNumber: (number: number) => Promise<Node | null>;

  addNode: (
    type: string,
    position: XYPosition,
    functionId?: string | null
  ) => Node<unknown> | null;

  connectNodes: (
    srcNode: Node,
    srcHandle: string | null,
    targetNode: Node
  ) => Edge;

  focusNode: (nodeId: string) => void;
  selectNodes: (nodeIds: string[]) => void;

  searchNodeNumber: (number: number) => Node[];
  searchNodeText: (text: string) => Node[];

  centerView: () => void;
  addIncludedFunctions: () => void;
}

// react flow instance and wrapper
const flowWrapper = document.getElementById("root");

const createFlowSlice: StateCreator<
  FlowSlice & ColorModeSlice & EditorSlice,
  [],
  [],
  FlowSlice
> = (set, get) => ({
  nodes: [],
  edges: [],
  selectedNodes: [],
  selectedEdges: [],
  flowInstance: undefined,
  filename: "Untitled.json",
  nodeNumberMax: 0,

  setFilename: (filename: string) => set({ filename: filename }),

  /**
   * This function is called when the react flow instance is initialized.
   * @param instance the react flow instance
   */
  onInit: (instance: ReactFlowInstance) => {
    // add included functions
    get().addIncludedFunctions();

    set({
      flowInstance: instance,
    });
  },

  /**
   * Get a node by it's number.
   * @param number the number of the node to get
   * @returns the node with the given number or null if it doesn't exist
   */
  getNodeFromNumber: (number: number) => {
    return new Promise<Node | null>((resolve) => {
      // search for the node
      const nodes = get().searchNodeNumber(number);
      if (nodes.length > 0) {
        resolve(nodes[0]);
      } else {
        resolve(null);
      }
    });
  },

  addIncludedFunctions: () => {
    // if we don't have a function with id "supervisor", add it
    if (!get().functions) {
      set({
        functions: [],
      });
    }
    if (!get().functions.some((func) => func.id === "supervisor")) {
      const supFunc = supervisorFunction();

      // re-number nodes in function
      supFunc.nodes.forEach((node) => {
        get().updateNodeNumber(node, true);
      });
      set({
        functions: [...get().functions, supFunc],
      });
    }
  },

  setNodesAndEdges: (nodes: Node[], edges: Edge[]) => {
    set({
      nodes: nodes,
      edges: edges,
    });
  },

  updateNodeNumberMax: () => {
    let max = 0;
    get().nodes.forEach((node) => {
      if (node.data.number && node.data.number > max) {
        max = node.data.number;
      }
    });
    // update for functions as well
    get().functions.forEach((func) => {
      func.nodes.forEach((node) => {
        if (node.data.number && node.data.number > max) {
          max = node.data.number;
        }
      });
    });

    set({
      nodeNumberMax: max + 1,
    });
  },

  /**
   * Connects two nodes with an edge
   * @param srcNode the source node
   * @param srcHandle the source node's handle
   * @param targetNode the target node
   */
  connectNodes: (srcNode: Node, srcHandle: string | null, targetNode: Node) => {
    const newEdge: Edge = {
      id: uuidv4(),
      source: srcNode.id,
      sourceHandle: `${srcNode.id}_res_${srcHandle}`,
      target: targetNode.id,
      targetHandle: null,
    };

    set({
      edges: [...get().edges, newEdge],
    });

    return newEdge;
  },

  /**
   * Centers the viewport on the nodes.
   * @returns void
   */
  centerView: () => {
    // return if we don't have a flow instance
    if (!get().flowInstance) return;

    // center view
    const flowInstance = get().flowInstance!;
    setTimeout(flowInstance.fitView);
  },

  /**
   * This function is called when the nodes change.
   * @param changes the changes that were made
   */
  onNodesChange: (changes: NodeChange[]) => {
    // if we're adding a node and the node doesn't have a number
    // assign it the next available number
    changes.forEach((change) => {
      // if we're deleting a node, and it's type is function_output_node
      // remove it from the function's exit nodes
      if (change.type === "remove") {
        // get node
        const remChange = change as NodeRemoveChange;
        const node = get().nodes.find((node) => node.id === remChange.id);
        if (!node) return;
        // check type
        if (node.type === "function_output") {
          // get current function if we're editing a function
          const func = get().functions.find(
            (func) => func.id === get().isEditingFunction
          );

          // if we're not editing a function, return
          if (!func) return;

          // remove node from function
          func.exitNodes = func.exitNodes.filter((id) => id !== node.id);
        }
      }

      // update node number for newly added nodes that don't have a number
      if (change.type !== "add") return;
      change = change as NodeAddChange;
      if (!change.item.data.number) {
        get().updateNodeNumber(change.item);
      }
    });

    set({
      nodes: applyNodeChanges(changes, get().nodes),
    });
  },

  /**
   * This function is called when the edges change.
   * @param changes the changes that were made
   */
  onEdgesChange: (changes: EdgeChange[]) => {
    set({
      edges: applyEdgeChanges(changes, get().edges),
    });
  },

  /**
   * This function is called when a connection is made between two nodes.
   * @param connection the connection that was made
   */
  onConnect: (connection: Connection) => {
    set({
      edges: addEdge(connection, get().edges),
    });

    // get newly added edge
    const newEdge = get().edges[get().edges.length - 1];

    // update color mode
    get().updateEdgeColorMode(newEdge.id);
  },

  /**
   * This function is called when a node from the
   * palette is dragged over the flow editor.
   * @param event the drag over event
   */
  onDragOver: (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  },

  /**
   * This function is called when a node is dropped onto the flow editor.
   * @param event the drop event
   * @returns void
   */
  onDrop: (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    // return if we don't have a flow instance
    if (!get().flowInstance) return;

    const type = event.dataTransfer.getData("application/reactflow");

    // check if type is function_call
    let functionId = null;
    if (type === "function_call") {
      // get function id from application/function
      functionId = event.dataTransfer.getData("application/function");
    }

    const bounds = flowWrapper!.getBoundingClientRect();
    const position = get().flowInstance!.screenToFlowPosition({
      x: event.clientX - bounds.left,
      y: event.clientY - bounds.top,
    });

    get().addNode(type, position, functionId);
  },

  /**
   * Adds a node to the flow.
   * @param type the string node type
   * @param position the position of the node
   * @param functionId the id of the function if we're adding a function call node
   * @returns
   */
  addNode: (type: string, position: XYPosition, functionId?: string | null) => {
    // make sure type is not empty
    if (!type) return null;

    // set common node properties
    const newNode: Node<unknown> = {
      id: uuidv4(),
      type: type,
      position: position!,
      data: {},
    };

    // add function id if we have one
    // this is for function_call nodes
    if (functionId) {
      newNode.data = {
        functionId: functionId,
      };
    }

    // special case for function output nodes
    // add them to their respective function's output node list
    if (type === "function_output_node") {
      // get current function if we're editing a function
      const func = get().functions.find(
        (func) => func.id === get().isEditingFunction
      );

      // if we're not editing a function, return
      if (!func) return null;

      // add output node to function
      func.exitNodes.push(newNode.id);
    }

    // assign node number
    get().updateNodeNumber(newNode);

    set({
      nodes: [...get().nodes, newNode],
    });

    return newNode;
  },

  /**
   * Select a node by id and zoom to it.
   * @param nodeId the id of the node to focus
   * @returns void
   */
  focusNode: (nodeId: string) => {
    set({
      nodes: get().nodes.map((node) => {
        if (node.id === nodeId) {
          // zoom to node
          if (get().flowInstance) {
            // get node bounds
            const nodePos = node.position;
            if (!nodePos) return node;
            const nodeBounds: Rect = {
              x: nodePos.x,
              y: nodePos.y,
              width: node.width || 100,
              height: node.height || 100,
            };
            get().flowInstance!.fitBounds(nodeBounds);
          }
        }
        return node;
      }),
    });
  },

  /**
   * Select a node by id.
   * @param nodeId the id of the node to select or null to deselect all nodes
   * @returns void
   */
  selectNodes: (nodeId: string[]) => {
    set({
      nodes: get().nodes.map((node) => {
        if (nodeId.includes(node.id)) {
          node.selected = true;
        } else {
          node.selected = false;
        }
        return node;
      }),
    });
  },

  /**
   * Binary search for a node by it's number.
   * We can do this because the node numbers should
   * always be in order.
   * @param number the number of the node to search for
   */
  searchNodeNumber: (number: number) => {
    // linear search nodes
    // unfortunately, node numbers are not always in order
    // so we can't use binary search
    return get().nodes.filter((node) => node.data.number === number) || [];
  },

  searchNodeText: (text: string) => {
    // search for nodes, include nodes containing the text
    return get().nodes.filter((node) =>
      node.data.content?.toLowerCase().includes(text.toLowerCase())
    );
  },

  /**
   * This function is called when the user selects or deselects nodes or edges.
   * @param OnNodeChangeFunc contains an object with the selected nodes and edges
   */
  onSelectionChange: ({ nodes, edges }) => {
    set({
      selectedNodes: nodes,
      selectedEdges: edges,
    });
  },

  updateNodeNumber: (node: Node, replace?: boolean) => {
    // check if we have a node number
    if (!node.data.number || replace) {
      // if we don't have a node number, assign it the next available number
      node.data.number = get().nodeNumberMax;
      set({
        nodeNumberMax: get().nodeNumberMax + 1,
      });
    } else {
      // if the node number is greater than the max, update the max
      if (node.data.number >= get().nodeNumberMax) {
        set({
          nodeNumberMax: node.data.number + 1,
        });
      }
    }
  },
});

export { createFlowSlice, type FlowSlice };
