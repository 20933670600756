import React, { useCallback, useEffect } from "react";

import {
  Handle,
  Node,
  NodeProps,
  Position,
  useUpdateNodeInternals,
} from "reactflow";
import NodeWrapper from "./NodeWrapper";

import { v4 as uuidv4 } from "uuid";

import { BiMessageRoundedAdd, BiSolidHappy } from "react-icons/bi";
import { Card } from "@chakra-ui/react";

import {
  Input,
  Textarea,
  Tooltip,
  IconButton,
  Heading,
  Stack,
  Text,
} from "@chakra-ui/react";

import { CloseIcon } from "@chakra-ui/icons";
import { BsFillChatLeftQuoteFill } from "react-icons/bs";

import { useColorModeValue } from "@chakra-ui/react";
import { useStore } from "../app/store";
import { shallow } from "zustand/shallow";

import { EditorSlice, NodeGroup } from "../app/slices/editorSlice";
import { Box, Select } from "@chakra-ui/react";
import { FaLayerGroup } from "react-icons/fa";
import { ColorModeSlice } from "../app/slices/colorModeSlice";

type DialogueResponse = {
  id: string;
  content: string;
  happy: boolean;
};

type DialogueNodeData = {
  content: string;
  responses: DialogueResponse[];
  group?: string;
  number?: number;
  audio?: ArrayBuffer;
};

type DialogueNode = Node<DialogueNodeData>;

const selector = (state: EditorSlice & ColorModeSlice) => ({
  nodeGroups: state.nodeGroups,
  updateNodeColorMode: state.updateNodeColorMode,
  collapsedNodes: state.collapsedNodes,
  toggleNodeCollapsed: state.toggleNodeCollapsed,
  setNodeGroup: state.setNodeGroup,
});

/**
 * Represents a dialogue node, this is a node that contains some dialogue content (text)
 * @returns
 */
function DialogueNodeComponent({ id, data }: NodeProps<DialogueNodeData>) {
  const updateNodeInternals = useUpdateNodeInternals();

  const {
    updateNodeColorMode,
    nodeGroups,
    collapsedNodes,
    setNodeGroup,
    toggleNodeCollapsed,
  } = useStore(selector, shallow);

  const [responses, setResponses] = React.useState<DialogueResponse[]>(
    data.responses
      ? data.responses
      : [
          {
            id: uuidv4(),
            content: "yes",
            happy: false,
          },
          {
            id: uuidv4(),
            content: "no",
            happy: false,
          },
        ]
  );

  const [collapsed, setCollapsed] = React.useState<boolean>(
    collapsedNodes.includes(id)
  );

  useEffect(() => {
    setCollapsed(collapsedNodes.includes(id));
  }, [collapsedNodes, id]);

  const [currentResponse, setCurrentResponse] =
    React.useState<DialogueResponse>({
      id: uuidv4(),
      content: "",
      happy: false,
    });

  // update data when responses change
  useEffect(() => {
    data.responses = responses;
  }, [data, responses]);

  // re-run color mode when happy or group changes
  useEffect(() => {
    updateNodeColorMode(id);
  }, [responses, data.group, updateNodeColorMode, id]);

  /**
   * Push the currently typed response from the input box
   * to the responses list and clear the input box
   */
  const pushCurrentResponse = useCallback(() => {
    if (currentResponse.content !== "") {
      responses.push(currentResponse);
      setCurrentResponse({
        id: uuidv4(),
        content: "",
        happy: false,
      });

      // update node internals
      // this is required when modifying handles
      updateNodeInternals(id);
    }
  }, [currentResponse, responses, id, updateNodeInternals]);

  /**
   * Removes a response from the responses list
   * @param index
   */
  const removeResponse = useCallback(
    (response: DialogueResponse) => {
      setResponses(responses.filter((res) => res !== response));

      // update node internals
      // this is required when modifying handles
      updateNodeInternals(id);
    },
    [responses, id, updateNodeInternals]
  );

  const bg = useColorModeValue("gray.300", "gray.600");

  const groupSelector = (): React.ReactNode => {
    if (nodeGroups.length === 0) {
      return <></>;
    }
    return (
      <Stack>
        <Select
          title="Group"
          variant="outline"
          value={data.group}
          icon={<FaLayerGroup />}
          aria-label="Group Selector"
          maxWidth={250}
          size="sm"
          onChange={(e) => {
            setNodeGroup(id, e.target.value);
          }}
        >
          <option value={undefined} key="none">
            None
          </option>
          {nodeGroups.map((group: NodeGroup) => {
            return (
              <option key={group.id} value={group.id}>
                {group.name}
              </option>
            );
          })}
        </Select>
      </Stack>
    );
  };

  return (
    <NodeWrapper
      title="Dialogue Node"
      id={`${data.number}` || "Undefined"}
      audio={data.audio}
      decoration={
        <BsFillChatLeftQuoteFill alignmentBaseline="middle" color="lightblue" />
      }
      collapsed={collapsed}
      selector={groupSelector()}
      onCollapsePressed={() => {
        toggleNodeCollapsed(id);
      }}
    >
      <Stack gap={3}>
        <Text
          style={{
            display: collapsed ? "block" : "none",
            maxWidth: "20rem",
          }}
        >
          {data.content ?? ""}
        </Text>
        <Box style={{ display: !collapsed ? "block" : "none" }}>
          <Textarea
            className="nodrag"
            placeholder="Enter dialogue content..."
            aria-label="Dialogue content input"
            defaultValue={data.content}
            style={{
              height: "100px",
              whiteSpace: "pre-wrap",
            }}
            onChange={(e) => {
              data.content = e.target.value;
            }}
          />
          <Heading size="sm">Responses</Heading>
          <Stack direction="row" gap={3}>
            <Input
              className="nodrag"
              type="text"
              aria-label="Dialogue new response input"
              placeholder="Enter response..."
              onKeyDown={(e) => {
                // support pressing enter to add a response
                if (e.key === "Enter") {
                  pushCurrentResponse();
                }
              }}
              value={currentResponse.content}
              onChange={(e) =>
                setCurrentResponse({
                  ...currentResponse,
                  content: e.target.value,
                })
              }
            ></Input>
            <Tooltip label="Add new response">
              <IconButton
                data-testid="add-response-btn"
                onClick={pushCurrentResponse}
                aria-label="Add new response"
                icon={<BiMessageRoundedAdd size={20} />}
              />
            </Tooltip>
          </Stack>
        </Box>

        <Stack gap={3}>
          <Stack direction="row" gap={8} className="response-container">
            {
              // loop through all responses and display them as badges
              responses !== undefined ? (
                responses.map((response: DialogueResponse, index: number) => {
                  return (
                    <Card
                      variant="outline"
                      key={index}
                      className="response-item"
                      style={{ maxWidth: "15rem" }}
                      bgColor={response.happy ? "green.400" : bg}
                    >
                      <Box style={{ display: !collapsed ? "block" : "none" }}>
                        <IconButton
                          isRound={true}
                          fontSize="14px"
                          icon={<CloseIcon />}
                          aria-label="Remove response"
                          className="corner-button-right"
                          variant="solid"
                          size="xs"
                          bgColor="red.800"
                          colorScheme="red"
                          onClick={() => removeResponse(response)}
                        />
                        <IconButton
                          isRound={true}
                          fontSize="14px"
                          icon={<BiSolidHappy />}
                          aria-label="Toggle happy response"
                          className="corner-button-left"
                          variant="solid"
                          size="xs"
                          bgColor={response.happy ? "green.400" : bg}
                          colorScheme={response.happy ? "green" : "gray"}
                          onClick={() => {
                            response.happy = !response.happy;
                            setResponses([...responses]);
                          }}
                        />
                      </Box>
                      <span style={{ textAlign: "center" }}>
                        {response.content}
                      </span>
                      <Handle
                        aria-label="Response handle output"
                        id={`${id}_res_${response.id}`}
                        className="response-handle"
                        type="source"
                        position={Position.Bottom}
                      />
                    </Card>
                  );
                })
              ) : (
                <></>
              )
            }
          </Stack>
        </Stack>
      </Stack>
      <Handle aria-label="Handle input" type="target" position={Position.Top} />
    </NodeWrapper>
  );
}

export { DialogueNodeComponent, type DialogueNode, type DialogueResponse };
