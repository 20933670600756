import React, { useEffect } from "react";

import { Handle, Node, NodeProps, Position } from "reactflow";

import NodeWrapper from "./NodeWrapper";

import { MdOutput } from "react-icons/md";
import { Heading, Input, Stack } from "@chakra-ui/react";
import { useStore } from "../app/store";

type FunctionOutputData = {
  number: number;
  name: string;
};

type FunctionOutput = Node<FunctionOutputData>;

/**
 * Represents a dialogue node, this is a node that contains some dialogue content (text)
 * @param param0
 * @returns
 */
function FunctionOutputComponent({ id, data }: NodeProps<FunctionOutputData>) {
  const setOutputNodeName = useStore((state) => state.setOutputNodeName);
  const [name, setName] = React.useState(data.name || `Output ${data.number}`);

  // set default name
  useEffect(() => {
    setOutputNodeName(id, name);
  }, [id, setOutputNodeName, name]);

  return (
    <NodeWrapper
      title="Function Output"
      id={`${data.number}` || "Undefined"}
      decoration={<MdOutput color="red" />}
    >
      <Handle type="target" position={Position.Top} />
      <Stack gap={3} mb={3}>
        <Heading size="sm">Name</Heading>
        <Input
          className="nodrag"
          onChange={(e) => {
            setName(e.target.value);
          }}
          value={name}
        />
      </Stack>
    </NodeWrapper>
  );
}

export { FunctionOutputComponent, type FunctionOutput };
