import { StateCreator } from "zustand";
import { FlowSlice } from "./flowSlice";
import { EditorSlice } from "./editorSlice";
import { DialogueNode } from "../../nodes/DialogueNode";
import { DialogueEntry } from "../../nodes/DialogueEntry";
import { v4 as uuidv4 } from "uuid";

interface FlowGeneratorSlice {
  generateFlow: (params: JobFlowParameters) => void;
}

/**
 * A job requirement is a single requirement for a job.
 * If a job requirement is marked as optional, then it is not required for employment.
 */
type JobRequirement = {
  type: "skill" | "experience" | "education" | "certification" | "other";
  description: string;
  isOptional: boolean;
};

/**
 * Parameters for a job, used to generate a job flow.
 */
type JobFlowParameters = {
  candidateName: string;
  jobTitle: string;
  jobPostSiteName: string;
  jobDescription: string;
  jobLocation: string;
  jobRequirements: JobRequirement[];
};

export type TemplateNode = {
  name: string;
  content: string;
  responses: string[];
};

export type TemplateEdge = {
  source: string;
  target: string; // either the name of a node, or the response text
};

export type NodeTemplate = {
  name: string;
  description: string;
  template: {
    nodes: TemplateNode[];
    edges: TemplateEdge[];
  };
};

const createFlowGeneratorSlice: StateCreator<
  FlowGeneratorSlice & FlowSlice & EditorSlice,
  [],
  [],
  FlowGeneratorSlice
> = (set, get) => ({
  /**
   * Generates a node flow based on the given job parameters.
   * @param params The parameters to use to generate the flow.
   */
  generateFlow: (params: JobFlowParameters) => {
    console.log("Generating flow with params: ", params);
    // add entry node
    const entry: DialogueEntry = get().addNode(
      "dialogue_entry",
      { x: 0, y: 0 },
      null
    )! as DialogueEntry;

    // add intro node
    const intro: DialogueNode = get().addNode(
      "dialogue_node",
      { x: 0, y: 0 },
      null
    )! as DialogueNode;

    // set intro node text
    intro.data.content = `Hi am I speaking with ${params.candidateName}?`;
    intro.data.responses = [
      { id: uuidv4(), content: "yes", happy: true },
      { id: uuidv4(), content: "no", happy: false },
    ];

    // connect intro and entry nodes
    get().connectNodes(entry, null, intro);

    // add candidate greeting node
    const candidateGreeting: DialogueNode = get().addNode(
      "dialogue_node",
      { x: 0, y: 0 },
      null
    )! as DialogueNode;
    candidateGreeting.data.content = `Hi ${params.candidateName}, This is Alex from LaunchCrew. You had applied to our ${params.jobTitle} position on ${params.jobPostSiteName}. How are you today?`;
    candidateGreeting.data.responses = [
      {
        id: uuidv4(),
        content: "I'm doing well",
        happy: true,
      },
      {
        id: uuidv4(),
        content: "I'm not interested in the position",
        happy: false,
      },
    ];

    // connect intro node to candidate greeting node
    get().connectNodes(intro, intro.data.responses[0].id, candidateGreeting);

    // add candidate goodbye node
    const candidateNegativeGoodbyeNode: DialogueNode = get().addNode(
      "dialogue_node",
      { x: 0, y: 0 },
      null
    )! as DialogueNode;
    candidateNegativeGoodbyeNode.data.content = "Sorry! Have a great day!";
    candidateNegativeGoodbyeNode.data.responses = [];

    // connect intro node to candidate goodbye node
    get().connectNodes(
      intro,
      intro.data.responses[1].id,
      candidateNegativeGoodbyeNode
    );

    // connect candidate greeting node to candidate goodbye node
    get().connectNodes(
      candidateGreeting,
      candidateGreeting.data.responses[1].id,
      candidateNegativeGoodbyeNode
    );

    // add job description intro node
    const jobDescriptionIntroNode: DialogueNode = get().addNode(
      "dialogue_node",
      { x: 0, y: 0 },
      null
    )! as DialogueNode;

    // set job description intro node text
    jobDescriptionIntroNode.data.content =
      "That's good to hear! I just wanted to share a few details and had a few quick questions for you regarding the position. Is that ok?";

    // set job description intro node responses
    jobDescriptionIntroNode.data.responses = [
      { id: uuidv4(), content: "yes", happy: true },
      { id: uuidv4(), content: "no", happy: false },
    ];

    // connect candidate greeting node to job description node
    get().connectNodes(
      candidateGreeting,
      candidateGreeting.data.responses[0].id,
      jobDescriptionIntroNode
    );

    // connect job description intro node to candidate goodbye node
    get().connectNodes(
      jobDescriptionIntroNode,
      jobDescriptionIntroNode.data.responses[1].id,
      candidateNegativeGoodbyeNode
    );

    // add job description node
    const jobDescriptionNode: DialogueNode = get().addNode(
      "dialogue_node",
      { x: 0, y: 0 },
      null
    )! as DialogueNode;

    // set job description node text
    jobDescriptionNode.data.content = `Got it, so a little bit about the assignment. It is a ${params.jobDescription}. Are you comfortable or experienced with this sort of an assignment?`;
    jobDescriptionNode.data.responses = [
      { id: uuidv4(), content: "yes", happy: true },
      { id: uuidv4(), content: "no", happy: false },
    ];

    // connect job description intro node to job description node
    get().connectNodes(
      jobDescriptionIntroNode,
      jobDescriptionIntroNode.data.responses[0].id,
      jobDescriptionNode
    );

    // add disqualify node
    const disqualifyNode: DialogueNode = get().addNode(
      "dialogue_node",
      { x: 0, y: 0 },
      null
    )! as DialogueNode;

    // set disqualify node text
    disqualifyNode.data.content = `Apologies, but it seems like you may not be the best fit for this position. Thank you for your time and have a great day!`;
    disqualifyNode.data.responses = [];

    // connect job description node to disqualify node
    get().connectNodes(
      jobDescriptionNode,
      jobDescriptionNode.data.responses[1].id,
      disqualifyNode
    );

    // add job requirements intro node
    const jobRequirementsIntroNode: DialogueNode = get().addNode(
      "dialogue_node",
      { x: 0, y: 0 },
      null
    )! as DialogueNode;

    // set job requirements intro node text
    jobRequirementsIntroNode.data.content = `Great! Next, I'll go over some requirements for the position. Is that ok?`;
    jobRequirementsIntroNode.data.responses = [
      { id: uuidv4(), content: "yes", happy: true },
      { id: uuidv4(), content: "no", happy: false },
    ];

    // connect to job description node
    get().connectNodes(
      jobDescriptionNode,
      jobDescriptionNode.data.responses[0].id,
      jobRequirementsIntroNode
    );

    // connect to good bye node
    get().connectNodes(
      jobRequirementsIntroNode,
      jobRequirementsIntroNode.data.responses[1].id,
      candidateNegativeGoodbyeNode
    );

    // start job requirements loop
    let lastRequirementNode: DialogueNode = jobRequirementsIntroNode;
    let lastRequirement: JobRequirement | undefined;
    params.jobRequirements.forEach((requirement) => {
      // add job requirement node
      const jobRequirementNode: DialogueNode = get().addNode(
        "dialogue_node",
        { x: 0, y: 0 },
        null
      )! as DialogueNode;

      // set job requirement node text
      jobRequirementNode.data.content = `Our ${requirement.type} requirement is ${requirement.description}. Do you meet this requirement?`;
      jobRequirementNode.data.responses = [
        { id: uuidv4(), content: "yes", happy: true },
        { id: uuidv4(), content: "no", happy: false },
      ];

      // connect to last requirement node
      get().connectNodes(
        lastRequirementNode,
        lastRequirementNode.data.responses[0].id,
        jobRequirementNode
      );

      // if the last requirement was optional, connect the negative output as well
      if (lastRequirement?.isOptional) {
        get().connectNodes(
          lastRequirementNode,
          lastRequirementNode.data.responses[1].id,
          jobRequirementNode
        );
      }

      lastRequirement = requirement;
      lastRequirementNode = jobRequirementNode;

      // connect to disqualify node if requirement is not optional
      if (!requirement.isOptional) {
        get().connectNodes(
          jobRequirementNode,
          jobRequirementNode.data.responses[1].id,
          disqualifyNode
        );
      }
    });

    // add job requirements outro node
    const jobRequirementsOutroNode: DialogueNode = get().addNode(
      "dialogue_node",
      { x: 0, y: 0 },
      null
    )! as DialogueNode;

    // set job requirements outro node text
    jobRequirementsOutroNode.data.content = `Great! Thanks for answering those questions. I'll pass this information along to the hiring team. In the meantime, I'll send you a link to schedule your interview. Have a great day!`;

    // connect to last requirements node
    get().connectNodes(
      lastRequirementNode,
      lastRequirementNode.data.responses[0].id,
      jobRequirementsOutroNode
    );

    get().setLayoutDirty();
  },
});

export {
  createFlowGeneratorSlice,
  type FlowGeneratorSlice,
  type JobFlowParameters,
  type JobRequirement,
};
