import { Node, Edge } from "reactflow";
import { ElkExtendedEdge, ElkNode } from "elkjs/lib/elk.bundled.js";
import ELK from "elkjs/lib/elk.bundled.js";
const elk = new ELK();

const elkOptions = {
  "elk.algorithm": "layered",
  "elk.layered.spacing.nodeNodeBetweenLayers": "200",
  "elk.edgeRouting": "POLYLINE",
  "elk.spacing.nodeNode": "80",
  "elk.direction": "DOWN",
  "elk.alignment": "LEFT",
  "elk.layered.nodePlacement.bk.fixedAlignment": "LEFTDOWN",
};

export const getLayoutedElements = (nodes: Node[], edges: Edge[]) => {
  if (nodes.length == 0) {
    return Promise.resolve({ nodes, edges });
  }
  const graph: ElkNode = {
    id: "root",
    layoutOptions: elkOptions,
    children: nodes.map((node) => {
      return {
        id: node.id,
        width: node.width || 500,
        height: node.height || 250,
        layoutOptions: elkOptions,
      } as ElkNode;
    }),
    edges: edges.map((edge) => {
      return {
        id: edge.id,
        sources: [edge.source],
        targets: [edge.target],
      } as ElkExtendedEdge;
    }),
  };

  return elk.layout(graph).then((data) => {
    // convert back to react flow format
    const layoutedNodes = data.children!.map((child: ElkNode) => {
      const originalNode = nodes.find((n) => n.id === child.id);
      return {
        ...originalNode,
        position: {
          x: child.x,
          y: child.y,
        },
      };
    });

    const layoutedEdges = data.edges!.map((edge: ElkExtendedEdge) => {
      const originalEdge = edges.find((e) => e.id === edge.id);
      return {
        ...originalEdge,
        source: edge.sources[0],
        target: edge.targets[0],
      };
    });

    return {
      nodes: layoutedNodes,
      edges: layoutedEdges,
    } as { nodes: Node[]; edges: Edge[] };
  });
};
