import { StateCreator } from "zustand";
import { FlowSlice } from "./flowSlice";

import { EditorSlice } from "./editorSlice";

interface NodeAudioSlice {
  setNodeAudio: (nodeId: string, audio: ArrayBuffer) => void;
}

const createNodeAudioSlice: StateCreator<
  NodeAudioSlice & FlowSlice & EditorSlice,
  [],
  [],
  NodeAudioSlice
> = (set, get) => ({
  /**
   * Sets the audio data for a node.
   * @param nodeId the id of the node to set the audio for
   * @param audio the audio data to set for the node
   */
  setNodeAudio: (nodeId: string, audio: ArrayBuffer) => {
    set({
      nodes: get().nodes.map((node) => {
        if (node.id === nodeId) {
          node.data = { ...node.data, audio: audio };
        }
        return node;
      }),
    });
  },
});

export { type NodeAudioSlice, createNodeAudioSlice };
