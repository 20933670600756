import { CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  ButtonGroup,
  Card,
  CardBody,
  Heading,
  IconButton,
  Input,
  InputGroup,
  Kbd,
  Slide,
  Stack,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useCallback, useEffect } from "react";
import { FaArrowDown, FaArrowUp, FaFont, FaHashtag } from "react-icons/fa";
import { useStore } from "../app/store";
import { Node } from "reactflow";

/**
 * The card displayed when the user presses Control
 * or Command plus Space
 * @param _props the properties
 * @returns the component
 */
function SearchPopup() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialFocusRef = React.useRef<HTMLInputElement>(null);

  const [searchText, setSearchText] = React.useState<string>("");
  const [lastSearchText, setLastSearchText] = React.useState<string>("");

  const [searchResults, setSearchResults] = React.useState<Node[]>([]);
  const [searchIndex, setSearchIndex] = React.useState<number>(0);
  const [searchMode, setSearchMode] = React.useState<"number" | "content">(
    "number"
  );

  const {
    searchNodeNumber,
    searchNodeText,
    focusNode,
    selectNodes,
    isEditingFunction,
  } = useStore((state) => state);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.ctrlKey || event.metaKey) && event.key === " ") {
        event.preventDefault();
        onOpen();
        initialFocusRef.current?.focus();
      }
      if (event.key === "Escape") {
        onClose();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      /*removes event listener on cleanup*/
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  // update selection when search index or results change
  useEffect(() => {
    if (searchResults.length > 0) {
      const res: Node = searchResults[searchIndex];
      if (res) {
        focusNode(res.id);
      }
    } else {
      focusNode("");
    }
  }, [searchIndex, searchResults, focusNode]);

  // set selection index and clear results when search mode changes
  // or when editing function changes
  useEffect(() => {
    setSearchIndex(0);
    setSearchResults([]);
  }, [searchMode, isEditingFunction]);

  // callback for updating search results
  const search = useCallback(() => {
    // if search text is the same as last search text, go to next result
    if (searchText === lastSearchText) {
      if (searchResults.length > 0) {
        if (searchIndex === searchResults.length - 1) {
          setSearchIndex(0);
        } else {
          setSearchIndex(searchIndex + 1);
        }
      }
      return;
    }

    // search for nodes
    setLastSearchText(searchText);
    let newResults: Node[] = [];
    if (searchMode === "number") {
      newResults = searchNodeNumber(Number.parseInt(searchText));
      setSearchResults(newResults);
    } else {
      newResults = searchNodeText(searchText);
      setSearchResults(newResults);
    }

    // update selection
    setSearchIndex(0);
    if (newResults.length > 0) {
      // focus first result
      focusNode(newResults[0].id);

      // select results
      selectNodes(newResults.map((node) => node.id));
    }
  }, [
    focusNode,
    lastSearchText,
    searchIndex,
    searchMode,
    searchNodeNumber,
    searchNodeText,
    searchResults.length,
    searchText,
    selectNodes,
  ]);

  return (
    <Box zIndex={100} width="100%" justifyContent="center">
      <Slide style={{ zIndex: 10 }} direction="bottom" in={isOpen}>
        <Card maxWidth="500px" margin="auto" size="sm" mb={2}>
          <CardBody>
            <Stack direction="row" spacing={2} justifyContent="space-between">
              <Heading mb={2} size="sm">
                Search Nodes with <Kbd>Enter</Kbd>
              </Heading>
              {
                <Stack direction="row" spacing={2}>
                  <Heading size="sm">
                    {searchResults.length > 0 ? searchIndex + 1 : 0}
                  </Heading>
                  <Heading size="sm">/</Heading>
                  <Heading size="sm">{searchResults.length}</Heading>
                </Stack>
              }
            </Stack>
            <InputGroup gap={3}>
              <Input
                type={searchMode == "number" ? "number" : "text"}
                ref={initialFocusRef}
                aria-label="Search nodes input"
                placeholder={
                  searchMode == "number"
                    ? "Search by number..."
                    : "Search by content..."
                }
                onChange={(event) => {
                  setSearchText(event.target.value);
                }}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    search();
                  }
                }}
              />
              <ButtonGroup isAttached>
                <Tooltip label="Search by number">
                  <IconButton
                    colorScheme={searchMode == "number" ? "blue" : "gray"}
                    onClick={() => setSearchMode("number")}
                    icon={<FaHashtag />}
                    aria-label="Search by number"
                  />
                </Tooltip>
                <Tooltip label="Search by content">
                  <IconButton
                    colorScheme={searchMode == "content" ? "blue" : "gray"}
                    onClick={() => setSearchMode("content")}
                    icon={<FaFont />}
                    aria-label="Search by content"
                  />
                </Tooltip>
              </ButtonGroup>
              <ButtonGroup isAttached>
                <IconButton
                  icon={<FaArrowUp />}
                  onClick={() => {
                    if (searchIndex === 0) {
                      setSearchIndex(searchResults.length - 1);
                    } else {
                      setSearchIndex(searchIndex - 1);
                    }
                  }}
                  aria-label="Previous search result"
                />
                <IconButton
                  onClick={() => {
                    if (searchIndex === searchResults.length - 1) {
                      setSearchIndex(0);
                    } else {
                      setSearchIndex(searchIndex + 1);
                    }
                  }}
                  icon={<FaArrowDown />}
                  aria-label="Next serach result"
                />
              </ButtonGroup>
              <IconButton
                onClick={onClose}
                icon={<CloseIcon />}
                aria-label="Close search box"
              />
            </InputGroup>
          </CardBody>
        </Card>
      </Slide>
    </Box>
  );
}

export default SearchPopup;
