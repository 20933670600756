import { DialogueNode, DialogueResponse } from "../nodes/DialogueNode"
import { NodeFlow } from "./slices/inputOutputSlice"
import { Node } from "reactflow"

export const generateFlowComparison = (flow1: NodeFlow, filename1: string, flow2: NodeFlow, filename2: string) => {

    const changedNodes: string[] = []

    flow1.nodes.forEach((node1: Node) => {
        flow2.nodes.forEach((node2: Node) => {
            // skip nodes that have the same exact object
            if (node1 === node2) return

            // skip nodes that have different numbers
            if (node1.data.number !== node2.data.number) return

            // skip nodes that aren't both dialogue nodes
            if (node1.type !== "dialogue_node" || node2.type !== "dialogue_node") return

            const nodeDifferences: string[] = []

            // check if the content is the same
            if ((node1 as DialogueNode).data.content !== (node2 as DialogueNode).data.content) {
                nodeDifferences.push(`Node ${node1.data.number} has different content.\n`)
            }

            // check if node group is the same
            if ((node1 as DialogueNode).data.group !== (node2 as DialogueNode).data.group) {
                nodeDifferences.push(`Node ${node1.data.number} has a different group.\n`)
            }

            // iterate through all responses in node1 and filter those that exist in node2
            const responsesNotIn2: DialogueResponse[] = (node1 as DialogueNode).data.responses.filter((response1: DialogueResponse) => {
                return !(node2 as DialogueNode).data.responses.some((response2: DialogueResponse) => {
                    return response1.content === response2.content
                })
            })
            const responsesNotIn1: DialogueResponse[] = (node2 as DialogueNode).data.responses.filter((response2: DialogueResponse) => {
                return !(node1 as DialogueNode).data.responses.some((response1: DialogueResponse) => {
                    return response1.content === response2.content
                })
            })

            // if there are responses in node1 that aren't in node2, add them to the differences
            if (responsesNotIn2.length > 0 || responsesNotIn1.length > 0) {
                nodeDifferences.push(`Node ${node1.data.number} has different responses:\n`)
                if (responsesNotIn2.length > 0) {
                    // first, note the file that the responses are in
                    nodeDifferences.push(`  Responses only in ${filename1}:\n`)
                    // then, list the responses
                    responsesNotIn2.forEach((response: DialogueResponse) => {
                        nodeDifferences.push(`  - ${response.content}\n`)
                    })
                }
                if (responsesNotIn1.length > 0) {
                    // first, note the file that the responses are in
                    nodeDifferences.push(`  Responses only in ${filename2}:\n`)
                    // then, list the responses
                    responsesNotIn1.forEach((response: DialogueResponse) => {
                        nodeDifferences.push(`  - ${response.content}\n`)
                    })
                }
                nodeDifferences.push("\n")
            }

            // check if node has any differences
            if (nodeDifferences.length === 0) return

            changedNodes.push(nodeDifferences.join(""))
        })
    })

    if (changedNodes.length > 0) {
        changedNodes.unshift(`== Changed nodes in ${filename1} and ${filename2} ==\n`)
        changedNodes.push("\n")
    }

    // concatenate the differences into a single string
    let differencesString = `Comparison between ${filename1} and ${filename2}:\n\n`
    differencesString += changedNodes.join("")

    // iterate through again to find nodes that are in flow1 but not in flow2
    const nodesNotIn2: Node[] = flow1.nodes.filter((node1: Node) => {
        return !flow2.nodes.some((node2: Node) => {
            return node1.data.number === node2.data.number
        })
    })
    const nodesNotIn1: Node[] = flow2.nodes.filter((node2: Node) => {
        return !flow1.nodes.some((node1: Node) => {
            return node1.data.number === node2.data.number
        })
    })

    // write new and deleted nodes
    if (nodesNotIn1.length > 0 || nodesNotIn2.length > 0) {
        if (nodesNotIn2.length > 0) {
            differencesString += `== Deleted nodes, not in ${filename2} ==\n`
            nodesNotIn2.forEach((node: Node) => {
                differencesString += `  - Node ${node.data.number}\n`
            })
        }

        differencesString += '\n'

        if (nodesNotIn1.length > 0) {
            differencesString += `== New nodes, added to ${filename2} ==\n`
            nodesNotIn1.forEach((node: Node) => {
                differencesString += `  - Node ${node.data.number}\n`
            })
        }
    }

    // if there are no differences, return
    if (differencesString === "") return null

    // otherwise, return the differences
    return differencesString
}