import { useCallback, useEffect, useLayoutEffect } from "react";
import ReactFlow, {
  DefaultEdgeOptions,
  NodeTypes,
  Controls,
  Background,
  MiniMap,
  Node,
  FitViewOptions,
} from "reactflow";

import { shallow } from "zustand/shallow";

// import react flow default styles
import "reactflow/dist/style.css";

import { useStore } from "./app/store";
import { Box } from "@chakra-ui/react";

// custom node imports
import { DialogueNodeComponent } from "./nodes/DialogueNode";
import { DialogueEntryComponent } from "./nodes/DialogueEntry";
import { FunctionCallComponent } from "./nodes/FunctionCallNode";
import { FunctionOutputComponent } from "./nodes/FunctionOutput";

import NodePalette from "./editor/Toolbox";
import MenuBar from "./editor/MenuBar";
import SearchPopup from "./editor/SearchPopup";
import { getLayoutedElements } from "./app/flowLayout";

// constants for react flow
const fitViewOptions: FitViewOptions = {
  padding: 0.2,
};

// constants for react flow
const defaultEdgeOptions: DefaultEdgeOptions = {
  animated: false,
};

// an object containing all the node types and
// their corresponding components
const nodeTypes: NodeTypes = {
  dialogue_node: DialogueNodeComponent,
  dialogue_entry: DialogueEntryComponent,
  function_call: FunctionCallComponent,
  function_output: FunctionOutputComponent,
};

// an array of names for the node palette and drag and drop
const toolboxNodeNames: Array<string> = [
  "Dialogue Node",
  "Dialogue Entry",
  "Function Output",
];

const minimapNodeColor = (node: Node) => {
  if (node.style === undefined) {
    return "lightblue";
  }

  if (node.style.borderColor === "inherit") {
    return "grey";
  }

  return node.style.borderColor || "lightblue";
};

/**
 * This component renders the react flow editor for manipulating nodes.
 * @returns
 */
function Flow() {
  // state for nodes and edges
  const {
    nodes,
    edges,
    onInit,
    onNodesChange,
    onEdgesChange,
    onConnect,
    onDragOver,
    onDrop,
    onCopy,
    onPaste,
    onSelectionChange,
    setNodesAndEdges,
    onMouseMove,
    setLayoutDirty,
    isLayoutDirty,
  } = useStore((state) => state, shallow);

  // register event handlers for copy/paste and mouse events
  useEffect(() => {
    document.addEventListener("copy", onCopy);
    document.addEventListener("paste", onPaste);
    document.addEventListener("mousemove", onMouseMove);
  }, [onCopy, onPaste, onMouseMove]);

  const onLayout = useCallback(() => {
    const ns = nodes;
    const es = edges;

    getLayoutedElements(ns, es).then(({ nodes, edges }) => {
      setNodesAndEdges(nodes, edges);
    });
  }, [nodes, edges]);

  // auto-layout when state of isLayoutDirty changes
  useEffect(() => {
    if (isLayoutDirty) {
      console.log("Layout dirty, recalculating...");
      onLayout();
      setLayoutDirty();
    }
  }, [isLayoutDirty]);

  // Calculate the initial layout on mount.
  useLayoutEffect(() => {
    onLayout();
  }, []);

  // render react flow
  return (
    <>
      <Box position="absolute" right={0} width="100%">
        <MenuBar onLayout={onLayout} />
        <NodePalette toolboxNodeNames={toolboxNodeNames} />
      </Box>
      <Box position="absolute" bottom={0} width="100%">
        <SearchPopup />
      </Box>
      <ReactFlow
        className="react-flow"
        nodes={nodes}
        edges={edges}
        onInit={onInit}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onDrop={onDrop}
        onDragOver={onDragOver}
        onConnect={onConnect}
        onSelectionChange={onSelectionChange}
        fitView
        fitViewOptions={fitViewOptions}
        minZoom={0.1}
        defaultEdgeOptions={defaultEdgeOptions}
        nodeTypes={nodeTypes}
        deleteKeyCode={["Delete", "Backquote"]}
      >
        <Background />
        <Controls />

        <MiniMap
          style={{
            marginLeft: 50,
          }}
          position="bottom-left"
          pannable
          zoomable
          nodeColor={minimapNodeColor}
          aria-label="Minimap, drag to move viewport and scroll to zoom"
        />
      </ReactFlow>
    </>
  );
}

export default Flow;
