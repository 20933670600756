/**
 * This file contains the state and event handlers for the flow editor.
 */

import { createCopyPasteSlice, CopyPasteSlice } from "./slices/copyPasteSlice";
import { createFlowSlice, FlowSlice } from "./slices/flowSlice";
import {
  createInputOutputSlice,
  InputOutputSlice,
} from "./slices/inputOutputSlice";
import { createColorModeSlice, ColorModeSlice } from "./slices/colorModeSlice";
import { createEditorSlice, EditorSlice } from "./slices/editorSlice";
import {
  FlowGeneratorSlice,
  createFlowGeneratorSlice,
} from "./slices/flowGenerator";
import { NodeAudioSlice, createNodeAudioSlice } from "./slices/nodeAudioSlice";
import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/vanilla/shallow";

// zustand store
const useStore = createWithEqualityFn<
  FlowSlice &
    CopyPasteSlice &
    InputOutputSlice &
    ColorModeSlice &
    EditorSlice &
    FlowGeneratorSlice &
    NodeAudioSlice
>()(
  (...a) => ({
    ...createNodeAudioSlice(...a),
    ...createFlowSlice(...a),
    ...createCopyPasteSlice(...a),
    ...createInputOutputSlice(...a),
    ...createColorModeSlice(...a),
    ...createEditorSlice(...a),
    ...createFlowGeneratorSlice(...a),
  }),
  shallow
);

export { useStore };
