import React from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Button,
  IconButton,
  List,
  ListItem,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { NodeFlow, parseJSONToFlow } from "../../app/slices/inputOutputSlice";
import { generateFlowComparison } from "../../app/compareFlows";
import { CloseIcon } from "@chakra-ui/icons";

type CompareToolModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
};

const CompareToolModal = (props: CompareToolModalProps) => {
  const [files, setFiles] = React.useState<{ name: string; flow: NodeFlow }[]>(
    []
  );

  const toast = useToast();

  /**
   * Gets the contents of a file by opening a file dialogue
   * @returns the contents of the file
   */
  const getFileContents = (): Promise<{
    contents: string;
    filename: string;
  }> => {
    // open a file dialogue
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "application/json";
    // don't resolve the promise until the user has selected a file
    return new Promise((resolve, reject) => {
      input.onchange = () => {
        const file = input.files?.item(0);
        if (file) {
          const reader = new FileReader();
          reader.readAsText(file, "UTF-8");
          reader.onload = (e) => {
            resolve({
              contents: e.target?.result as string,
              filename: file.name,
            });
          };
          reader.onerror = (e) => {
            reject(e);
          };
        }
      };
      input.click();
      // remove the input element from the DOM
      input.remove();
    });
  };

  const addFileForComparison = () => {
    // open file dialogue
    getFileContents().then(({ contents, filename }) => {
      parseJSONToFlow(contents, filename)
        .catch((e) => {
          toast({
            title: "Error parsing file",
            description: e,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
          props.onClose();
        })
        .then((flow) => {
          // if we already have two files, display a toast and don't add the file
          if (files.length >= 2) {
            toast({
              title: "Error",
              description: "You can only compare two files at a time",
              status: "error",
              duration: 9000,
              isClosable: true,
            });
            return;
          }
          setFiles([...files, { name: filename, flow: flow! }]);
        });
    });
  };

  const finalRef = React.useRef(null);
  return (
    <>
      <Modal
        finalFocusRef={finalRef}
        isOpen={props.isOpen}
        onClose={props.onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Compare Flows</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Please upload two JSON files to compare.
            <Stack gap={3} direction="row" mt={3}>
              <Button
                onClick={() => {
                  addFileForComparison();
                }}
                colorScheme="blue"
              >
                Add File
              </Button>
            </Stack>
            <List gap={6} mt={3}>
              {files.map((file) => {
                return (
                  <ListItem key={file.name}>
                    <IconButton
                      mr={5}
                      size="xs"
                      onClick={() => {
                        setFiles(files.filter((f) => f.name !== file.name));
                      }}
                      aria-label="Remove file"
                      icon={<CloseIcon />}
                    />
                    {file.name}
                  </ListItem>
                );
              })}
            </List>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="green"
              mr={3}
              onClick={() => {
                if (files.length !== 2) {
                  // display toast
                  toast({
                    title: "Error",
                    description: "You must select two files to compare",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                  });
                  return;
                }
                // compare the two flows
                const result = generateFlowComparison(
                  files[0].flow,
                  files[0].name,
                  files[1].flow,
                  files[1].name
                );

                if (result === null) {
                  toast({
                    title: "Flows are identical",
                    status: "success",
                    duration: 9000,
                    isClosable: true,
                  });
                  return;
                }

                // download the result as a txt file
                const element = document.createElement("a");
                const file = new Blob([result], { type: "text/plain" });
                element.href = URL.createObjectURL(file);
                element.download = `${files[0].name.replace(
                  ".json",
                  ""
                )}-comparison.txt`;
                document.body.appendChild(element); // Required for this to work in FireFox
                element.click();

                props.onSuccess();
                props.onClose();
              }}
            >
              Confirm
            </Button>
            <Button colorScheme="red" onClick={props.onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CompareToolModal;
