import React, { PropsWithChildren } from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Button,
} from "@chakra-ui/react";

type ConfirmModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
};

const ConfirmModal = (props: PropsWithChildren<ConfirmModalProps>) => {
  const finalRef = React.useRef(null);
  return (
    <>
      <Modal
        finalFocusRef={finalRef}
        isOpen={props.isOpen}
        onClose={props.onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Action</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{props.children}</ModalBody>

          <ModalFooter>
            <Button
              aria-label="Confirm"
              colorScheme="green"
              mr={3}
              onClick={() => {
                props.onSuccess();
                props.onClose();
              }}
            >
              Confirm
            </Button>
            <Button
              aria-label="Cancel"
              colorScheme="red"
              onClick={props.onClose}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ConfirmModal;
