import React, { PropsWithChildren } from "react";

import { MdContentCopy } from "react-icons/md";
import {
  Box,
  IconButton,
  Button,
  Heading,
  Stack,
  Card,
  CardBody,
  CardHeader,
} from "@chakra-ui/react";
import {
  FaPlay,
  FaStop,
  FaWindowMaximize,
  FaWindowMinimize,
} from "react-icons/fa";

type NodeWrapperProps = {
  title: string;
  id: string;
  decoration?: React.ReactNode;
  selector?: React.ReactNode;
  collapsed?: boolean;
  audio?: ArrayBuffer;
  onCollapsePressed?: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

/**
 * Truncates a UUID for display purposes
 * @param uuid The UUID to truncate
 * @returns The truncated UUID
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const truncateUUID = (uuid: string) => {
  // return last 8 characters of uuid
  return "..." + uuid.slice(-8);
};

const getURLFromAudio = (audio: ArrayBuffer) => {
  const blob = new Blob([audio], { type: "audio/mpeg" });
  const url = URL.createObjectURL(blob);
  return url;
};

/**
 * Custom hook for managing audio playback
 * @param src url of the audio file
 * @returns
 */
const useAudio = (src: string | undefined) => {
  const audio = React.useRef(new Audio(src));

  return audio.current;
};

/**
 * Contains the UI layout for the background of all nodes
 */
function NodeWrapper(props: PropsWithChildren<NodeWrapperProps>) {
  const audio = useAudio(getURLFromAudio(props.audio as ArrayBuffer));
  const [isPlaying, setIsPlaying] = React.useState(false);

  React.useEffect(() => {
    audio.src = getURLFromAudio(props.audio as ArrayBuffer);
  }, [props.audio]);

  React.useEffect(() => {
    audio.addEventListener("ended", () => {
      setIsPlaying(false);
    });
    return () => {
      audio.removeEventListener("ended", () => {
        setIsPlaying(false);
      });
    };
  }, [audio]);

  return (
    <Card
      variant="outline"
      size="sm"
      className="node-wrapper"
      data-testid={`node_instance_${props.title}`}
    >
      <CardHeader minWidth={400}>
        <Stack spacing={3} justifyContent="space-between">
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row">
              {props.decoration}
              <Heading size="sm">{props.title}</Heading>
            </Stack>
            {props.collapsed != undefined ? (
              <IconButton
                style={{
                  float: "right",
                  width: "25px",
                  height: "25px",
                }}
                size="xs"
                onClick={(e) => {
                  props.onCollapsePressed?.(e);
                }}
                icon={
                  props.collapsed ? <FaWindowMaximize /> : <FaWindowMinimize />
                }
                aria-label="Collapse node"
              />
            ) : null}
          </Stack>
          <Box style={{ display: !props.collapsed ? "block" : "none" }}>
            <Stack direction="row">
              <Button
                size="sm"
                style={{ cursor: "pointer" }}
                leftIcon={<MdContentCopy size={15} />}
                aria-label="Copy node ID to clipboard"
                onClick={() => navigator.clipboard.writeText(props.id)}
                className="node-wrapper-id"
              >
                Node #: {props.id}
              </Button>
              {props.selector}
              {props.audio ? (
                <IconButton
                  aria-label="Play audio"
                  float="right"
                  size="sm"
                  colorScheme={isPlaying ? "red" : "green"}
                  icon={isPlaying ? <FaStop /> : <FaPlay />}
                  onClick={() => {
                    if (!isPlaying) {
                      audio.play();
                      setIsPlaying(true);
                    } else {
                      audio.currentTime = 0;
                      audio.pause();
                      setIsPlaying(false);
                    }
                  }}
                />
              ) : null}
            </Stack>
          </Box>
        </Stack>
      </CardHeader>
      <CardBody className="node-wrapper-content">{props.children}</CardBody>
    </Card>
  );
}

export default NodeWrapper;
