import React from "react";

import { Handle, Node, NodeProps, Position } from "reactflow";

import NodeWrapper from "./NodeWrapper";

import { FaFlag } from "react-icons/fa";

type DialogueEntryData = {
  number: number;
};

type DialogueEntry = Node<DialogueEntryData>;

/**
 * Represents a dialogue node, this is a node that contains some dialogue content (text)
 * @param param0
 * @returns
 */
function DialogueEntryComponent({ data }: NodeProps<DialogueEntryData>) {
  return (
    <NodeWrapper
      title="Dialogue Entry"
      id={`${data.number}` || "Undefined"}
      decoration={<FaFlag color="lightgreen" />}
    >
      <Handle type="source" position={Position.Bottom} />
    </NodeWrapper>
  );
}

export { DialogueEntryComponent, type DialogueEntry };
