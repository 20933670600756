export const supervisorFunction = () => {
    return {
        "name": "Supervisor",
        "id": "supervisor",
        "exitNodes": ["ef877ca8-04dd-497f-a6f3-8cbd5ed8629b"],
        "nodes": [
            {
                "id": "1a04aa1d-b7fa-4540-b9e3-68c754440257",
                "type": "dialogue_entry",
                "position": {
                    "x": 257.26832514072345,
                    "y": -489.9629339331069
                },
                "data": {
                    "number": 0
                },
                "width": 406,
                "height": 99,
                "style": {
                    "borderColor": "inherit"
                }
            },
            {
                "id": "abb47588-8761-4b6b-b941-a4e0195058eb",
                "type": "dialogue_node",
                "position": {
                    "x": 30.73464741257277,
                    "y": -224.4054784241631
                },
                "data": {
                    "number": 1,
                    "responses": [
                        {
                            "id": "fbc9970b-2c74-4ce6-8d82-66db58105741",
                            "content": "yes",
                            "happy": false
                        },
                        {
                            "id": "a0e388ad-d8bd-4712-af84-9532803a15b7",
                            "content": "no",
                            "happy": false
                        },
                        {
                            "id": "08b10864-b087-454a-9fec-786b4c3cb04c",
                            "content": "I'm busy right now",
                            "happy": false
                        },
                        {
                            "id": "1e87a16f-fac9-49ae-bd1f-ca1a53e8f26c",
                            "content": "I have a few more questions for you",
                            "happy": false
                        },
                        {
                            "id": "95f89e22-4813-4e8e-a8c9-8757c471a5af",
                            "content": "I can conference w them later, we can continue our conversation though.",
                            "happy": false
                        }
                    ],
                    "content": "Actually, can I conference you with my HR director, they would have more details about the process. Can you hold for a minute please as I add them?"
                },
                "style": {
                    "borderColor": "inherit"
                },
                "width": 880,
                "height": 302,
                "selected": false,
                "dragging": false,
                "positionAbsolute": {
                    "x": 30.73464741257277,
                    "y": -224.4054784241631
                }
            },
            {
                "id": "eb1c3746-800c-49d6-9510-ef66fd1cf788",
                "type": "dialogue_node",
                "position": {
                    "x": -371.14532832390853,
                    "y": 449.08424899004956
                },
                "data": {
                    "number": 2,
                    "responses": [],
                    "content": "Great! Adding them to the call right now, just hold for a second."
                },
                "style": {
                    "borderColor": "inherit"
                },
                "width": 406,
                "height": 302,
                "selected": false,
                "positionAbsolute": {
                    "x": -371.14532832390853,
                    "y": 449.08424899004956
                },
                "dragging": false
            },
            {
                "id": "8c8fd104-cc29-4e75-8bac-fd3f06a42d89",
                "type": "dialogue_node",
                "position": {
                    "x": 138.42631071285342,
                    "y": 447.30252997243815
                },
                "data": {
                    "number": 3,
                    "responses": [
                        {
                            "id": "08096e97-c9a4-4f89-9244-b914b59352ed",
                            "content": "GIVES TIME",
                            "happy": false
                        }
                    ],
                    "content": "I see, What time works best for you today or in the coming days?"
                },
                "style": {
                    "borderColor": "inherit"
                },
                "width": 406,
                "height": 302,
                "selected": false,
                "positionAbsolute": {
                    "x": 138.42631071285342,
                    "y": 447.30252997243815
                },
                "dragging": false
            },
            {
                "id": "c82745d1-d616-4903-858e-987f18f1835d",
                "type": "dialogue_node",
                "position": {
                    "x": 642.6527926967821,
                    "y": 474.02831523660416
                },
                "data": {
                    "number": 4,
                    "responses": [],
                    "content": "Sure, what questions do you have?"
                },
                "style": {
                    "borderColor": "inherit"
                },
                "width": 406,
                "height": 302,
                "selected": false,
                "positionAbsolute": {
                    "x": 642.6527926967821,
                    "y": 474.02831523660416
                },
                "dragging": false
            },
            {
                "id": "ef877ca8-04dd-497f-a6f3-8cbd5ed8629b",
                "type": "function_output",
                "position": {
                    "x": 1159.351307803988,
                    "y": 477.5917532718262
                },
                "data": {
                    "number": 5,
                    "name": "Return to next node",
                },
                "width": 406,
                "height": 302,
                "selected": false,
                "positionAbsolute": {
                    "x": 1159.351307803988,
                    "y": 477.5917532718262
                },
                "dragging": false
            },
            {
                "id": "8deb84b8-d03f-4400-8ae2-97e83caee36c",
                "type": "dialogue_node",
                "position": {
                    "x": 104.57364937824329,
                    "y": 880.2602512519246
                },
                "data": {
                    "number": 6,
                    "responses": [],
                    "content": "I will arrange a call for that time. Thanks for your time, have a good one!"
                },
                "style": {
                    "borderColor": "inherit"
                },
                "width": 406,
                "height": 302,
                "selected": false,
                "positionAbsolute": {
                    "x": 104.57364937824329,
                    "y": 880.2602512519246
                },
                "dragging": false
            }
        ],
        "edges": [
            {
                "animated": false,
                "source": "abb47588-8761-4b6b-b941-a4e0195058eb",
                "sourceHandle": "abb47588-8761-4b6b-b941-a4e0195058eb_res_fbc9970b-2c74-4ce6-8d82-66db58105741",
                "target": "eb1c3746-800c-49d6-9510-ef66fd1cf788",
                "targetHandle": null,
                "id": "reactflow__edge-abb47588-8761-4b6b-b941-a4e0195058ebabb47588-8761-4b6b-b941-a4e0195058eb_res_fbc9970b-2c74-4ce6-8d82-66db58105741-eb1c3746-800c-49d6-9510-ef66fd1cf788",
                "style": {
                    "stroke": "#1cf788"
                }
            },
            {
                "animated": false,
                "source": "abb47588-8761-4b6b-b941-a4e0195058eb",
                "sourceHandle": "abb47588-8761-4b6b-b941-a4e0195058eb_res_a0e388ad-d8bd-4712-af84-9532803a15b7",
                "target": "8c8fd104-cc29-4e75-8bac-fd3f06a42d89",
                "targetHandle": null,
                "id": "reactflow__edge-abb47588-8761-4b6b-b941-a4e0195058ebabb47588-8761-4b6b-b941-a4e0195058eb_res_a0e388ad-d8bd-4712-af84-9532803a15b7-8c8fd104-cc29-4e75-8bac-fd3f06a42d89",
                "style": {
                    "stroke": "#a42d89"
                }
            },
            {
                "animated": false,
                "source": "abb47588-8761-4b6b-b941-a4e0195058eb",
                "sourceHandle": "abb47588-8761-4b6b-b941-a4e0195058eb_res_08b10864-b087-454a-9fec-786b4c3cb04c",
                "target": "8c8fd104-cc29-4e75-8bac-fd3f06a42d89",
                "targetHandle": null,
                "id": "reactflow__edge-abb47588-8761-4b6b-b941-a4e0195058ebabb47588-8761-4b6b-b941-a4e0195058eb_res_08b10864-b087-454a-9fec-786b4c3cb04c-8c8fd104-cc29-4e75-8bac-fd3f06a42d89",
                "style": {
                    "stroke": "#a42d89"
                }
            },
            {
                "animated": false,
                "source": "abb47588-8761-4b6b-b941-a4e0195058eb",
                "sourceHandle": "abb47588-8761-4b6b-b941-a4e0195058eb_res_1e87a16f-fac9-49ae-bd1f-ca1a53e8f26c",
                "target": "c82745d1-d616-4903-858e-987f18f1835d",
                "targetHandle": null,
                "id": "reactflow__edge-abb47588-8761-4b6b-b941-a4e0195058ebabb47588-8761-4b6b-b941-a4e0195058eb_res_1e87a16f-fac9-49ae-bd1f-ca1a53e8f26c-c82745d1-d616-4903-858e-987f18f1835d",
                "style": {
                    "stroke": "#f1835d"
                }
            },
            {
                "animated": false,
                "source": "abb47588-8761-4b6b-b941-a4e0195058eb",
                "sourceHandle": "abb47588-8761-4b6b-b941-a4e0195058eb_res_95f89e22-4813-4e8e-a8c9-8757c471a5af",
                "target": "ef877ca8-04dd-497f-a6f3-8cbd5ed8629b",
                "targetHandle": null,
                "id": "reactflow__edge-abb47588-8761-4b6b-b941-a4e0195058ebabb47588-8761-4b6b-b941-a4e0195058eb_res_95f89e22-4813-4e8e-a8c9-8757c471a5af-ef877ca8-04dd-497f-a6f3-8cbd5ed8629b",
                "style": {
                    "stroke": "#d8629b"
                }
            },
            {
                "animated": false,
                "source": "8c8fd104-cc29-4e75-8bac-fd3f06a42d89",
                "sourceHandle": "8c8fd104-cc29-4e75-8bac-fd3f06a42d89_res_08096e97-c9a4-4f89-9244-b914b59352ed",
                "target": "8deb84b8-d03f-4400-8ae2-97e83caee36c",
                "targetHandle": null,
                "id": "reactflow__edge-8c8fd104-cc29-4e75-8bac-fd3f06a42d898c8fd104-cc29-4e75-8bac-fd3f06a42d89_res_08096e97-c9a4-4f89-9244-b914b59352ed-8deb84b8-d03f-4400-8ae2-97e83caee36c",
                "style": {
                    "stroke": "#aee36c"
                }
            },
            {
                "animated": false,
                "source": "1a04aa1d-b7fa-4540-b9e3-68c754440257",
                "sourceHandle": null,
                "target": "abb47588-8761-4b6b-b941-a4e0195058eb",
                "targetHandle": null,
                "id": "reactflow__edge-1a04aa1d-b7fa-4540-b9e3-68c754440257abb47588-8761-4b6b-b941-a4e0195058eb",
                "style": {
                    "stroke": "#a4e019"
                }
            }
        ],
    }
}