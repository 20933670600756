import { validate as isValidUuid } from "uuid";

/**
 * Converts a node's UUID to a nice color.
 * @param nodeUUID the uuid of the node
 * @param throwIfInvalid whether to throw an error if the UUID is invalid
 */
const nodeUUIDToColor = (nodeUUID: string, throwIfInvalid: boolean = true) => {
  if (!isValidUuid(nodeUUID)) {
    if (throwIfInvalid) {
      throw new Error("Node has invalid UUID: " + nodeUUID);
    } else {
      return "#2f2f2f";
    }
  }

  const encodedUuid = BigInt(encodeBaseTen(nodeUUID));

  const colorCode = Number(encodedUuid % BigInt(0x1000000));
  const red = colorCode >> 16;
  const green = (colorCode >> 8) & 0xff;
  const blue = colorCode & 0xff;

  const colorHex = rgbToHex(red, green, blue);
  return colorHex;
};

/**
 * Gets a text color that contrasts with the given hex color.
 * @param hex the hex color to contrast with
 */
const getTextColor = (hex: string) => {
  const r = parseInt(hex.substr(1, 2), 16);
  const g = parseInt(hex.substr(3, 2), 16);
  const b = parseInt(hex.substr(5, 2), 16);

  const brightness = r * 0.299 + g * 0.587 + b * 0.114;
  return brightness > 186 ? "#000000" : "#ffffff";
};

/**
 * Converts an RGB color to a hex color.
 * @param r the red value (0-255)
 * @param g the green value (0-255)
 * @param b the blue value (0-255)
 * @returns the hex color string
 */
const rgbToHex = (r: number, g: number, b: number) => {
  const componentToHex = (c: number) => {
    const hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  };
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
};

/**
 * Encodes a UUID to base 10.
 * @param uuid the uuid to encode
 * @returns the base 10 encoded uuid
 */
const encodeBaseTen = (uuid: string) => {
  const baseTen = uuid
    .split("")
    .map((char) => char.charCodeAt(0))
    .join("");
  return baseTen;
};

export { nodeUUIDToColor, getTextColor };
