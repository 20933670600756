import React from "react";
import { Card } from "@chakra-ui/react";

// icons
import { MdOutlineDragIndicator } from "react-icons/md";
import { Heading, Stack, Text } from "@chakra-ui/react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  IconButton,
  Input,
  InputGroup,
  Tag,
  TagCloseButton,
  TagLabel,
  TagLeftIcon,
  Tooltip,
  Wrap,
  WrapItem,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

import { useStore } from "../app/store";
import {
  EditorSlice,
  NodeFunction,
  NodeGroup,
} from "../app/slices/editorSlice";
import ColorPicker from "./ColorPicker";
import { ColorModeSlice } from "../app/slices/colorModeSlice";
import {
  FaBolt,
  FaLayerGroup,
  FaPalette,
  FaWrench,
  FaPlus,
} from "react-icons/fa";
import { CloseIcon } from "@chakra-ui/icons";
import ConfirmModal from "./modals/ConfirmModal";
import { nodeUUIDToColor } from "../app/utility";

const selector = (state: EditorSlice & ColorModeSlice) => ({
  nodeGroups: state.nodeGroups,
  addNodeGroup: state.addNodeGroup,
  removeNodeGroup: state.removeNodeGroup,
  updateNodeColorMode: state.updateNodeColorMode,
  addFunction: state.addFunction,
  removeFunction: state.removeFunction,
  editFunction: state.editFunction,
  functions: state.functions,
  isEditingFunction: state.isEditingFunction,
});

type NodePaletteProps = {
  toolboxNodeNames: Array<string>;
};

/**
 * Called when a node from the palette is dragged over the canvas
 * @param event  The drag event
 * @param nodeName The name of the node being dragged
 */
const onDragStart = (event: React.DragEvent<HTMLElement>, nodeName: string) => {
  event.dataTransfer.setData(
    "application/reactflow",
    nodeName.toLowerCase().replace(" ", "_")
  );
  event.dataTransfer.effectAllowed = "move";
};

const onDragStartFunction = (
  event: React.DragEvent<HTMLElement>,
  functionId: string
) => {
  event.dataTransfer.setData("application/reactflow", "function_call");
  event.dataTransfer.setData("application/function", functionId);
  event.dataTransfer.effectAllowed = "move";
};

/**
 * This component renders the node palette, which contains the nodes that can be dragged into the canvas.
 * @param props The props for this component
 * @returns The node palette component
 */
function NodePalette(props: NodePaletteProps) {
  const {
    nodeGroups,
    addNodeGroup,
    removeNodeGroup,
    updateNodeColorMode,
    addFunction,
    removeFunction,
    editFunction,
    functions,
    isEditingFunction,
  } = useStore(selector);

  const pushCurrentGroup = () => {
    if (currentGroup !== null && currentGroup.name !== "") {
      addNodeGroup(currentGroup!.color, currentGroup!.name);
      // clear current group
      setCurrentGroup({
        id: "",
        name: "",
        color: "#f56565",
      });
    }
  };

  const [currentGroup, setCurrentGroup] = React.useState<NodeGroup>({
    id: "",
    name: "",
    color: "#f56565",
  });

  const [currentFunction, setCurrentFunction] = React.useState<string>("");

  const toast = useToast();

  // state for the function delete confirm modal
  const {
    isOpen: functionDeleteModalIsOpen,
    onOpen: functionDeleteModalOpen,
    onClose: functionDeleteModalClose,
  } = useDisclosure();
  const [functionToDelete, setFunctionToDelete] = React.useState<string>("");

  return (
    <Card
      className="node-palette"
      aria-label="Node Palette"
      size="sm"
      style={{ margin: 10, marginTop: 0, maxWidth: 400 }}
    >
      <ConfirmModal
        isOpen={functionDeleteModalIsOpen}
        onClose={functionDeleteModalClose}
        onSuccess={() => {
          removeFunction(functionToDelete);
        }}
      >
        Are you sure you want to delete this function? All function calls
        referencing this function will be marked as undefined.
      </ConfirmModal>
      <Accordion defaultIndex={[0]} allowMultiple>
        <AccordionItem>
          <AccordionButton aria-label="Collapse toolbox panel" minWidth={400}>
            <Heading size="md" userSelect="none">
              Toolbox
            </Heading>
            <AccordionIcon marginLeft="auto" />
          </AccordionButton>
          <AccordionPanel>
            <Box marginBottom={5}>
              <Stack direction="row" alignItems="center">
                <FaPalette />
                <Heading size="sm" userSelect="none">
                  Node Palette
                </Heading>
              </Stack>
              <Text userSelect="none">
                Drag nodes into the canvas to create them
              </Text>
            </Box>
            <Stack gap={3}>
              {props.toolboxNodeNames
                .filter((n) => {
                  if (isEditingFunction === null) {
                    return n !== "Function Output";
                  } else {
                    return true;
                  }
                })
                .map((nodeName, idx) => {
                  return (
                    <Tag
                      tabIndex={idx}
                      size="lg"
                      style={{ cursor: "move", fontSize: 20 }}
                      onDragStart={(event) => onDragStart(event, nodeName)}
                      data-testid={`nodepalette-${nodeName}`}
                      key={nodeName}
                      aria-label={`Drag ${nodeName} into the canvas`}
                      draggable
                    >
                      <TagLeftIcon as={MdOutlineDragIndicator} />
                      {nodeName}
                    </Tag>
                  );
                })}
            </Stack>
            <Box marginBottom={5} marginTop={5}>
              <Stack direction="row" alignItems="center">
                <FaLayerGroup />
                <Heading size="sm" userSelect="none">
                  Node Groups
                </Heading>
              </Stack>
              <Text userSelect="none">
                Group nodes to make them easier to find
              </Text>
            </Box>
            <Stack gap={3}>
              <InputGroup gap={3}>
                <Input
                  aria-label="Group Input"
                  value={currentGroup!.name}
                  onChange={(e) => {
                    setCurrentGroup({
                      ...currentGroup!,
                      name: e.target.value,
                    });
                  }}
                  onKeyDown={(e) => {
                    // support pressing enter to add a response
                    if (e.key === "Enter") {
                      pushCurrentGroup();
                    }
                  }}
                  placeholder="Enter a group name"
                  size="md"
                />
                <Tooltip label="Add new group">
                  <IconButton
                    data-testid="add-group-btn"
                    onClick={() => {
                      pushCurrentGroup();
                    }}
                    aria-label="Add new group"
                    icon={<FaPlus size={20} />}
                  />
                </Tooltip>
                <ColorPicker
                  color={currentGroup!.color}
                  onChange={(color: string) => {
                    setCurrentGroup({
                      ...currentGroup!,
                      color: color,
                    });
                  }}
                />
              </InputGroup>
              <Wrap gap={2}>
                {nodeGroups.map((group: NodeGroup) => {
                  return (
                    <WrapItem key={group.id}>
                      <Tag
                        size="md"
                        borderRadius="full"
                        variant="solid"
                        bgColor={group.color}
                      >
                        <TagLabel>{group.name}</TagLabel>
                        <TagCloseButton
                          onClick={() => {
                            removeNodeGroup(group.id);
                            // update colors
                            updateNodeColorMode();
                          }}
                        />
                      </Tag>
                    </WrapItem>
                  );
                })}
              </Wrap>
            </Stack>
            {!isEditingFunction ? (
              <>
                <Box marginBottom={5} marginTop={5}>
                  <Stack direction="row" alignItems="center">
                    <FaBolt />
                    <Heading size="sm" userSelect="none">
                      Functions
                    </Heading>
                  </Stack>
                  <Text userSelect="none">
                    Package sub-trees into a single node for commonly used
                    dialogue
                  </Text>
                </Box>
                <Stack gap={3}>
                  <Stack
                    gap={3}
                    maxHeight={200}
                    overflow="auto"
                    data-testid="function-list"
                  >
                    {functions.map((func: NodeFunction) => {
                      return (
                        <Tag
                          data-testid={`function-${func.name}`}
                          style={{ cursor: "move", fontSize: 20 }}
                          key={func.id}
                          size="lg"
                          onDragStart={(event) =>
                            onDragStartFunction(event, func.id)
                          }
                          draggable
                        >
                          <Box
                            bgColor={nodeUUIDToColor(func.id, false)}
                            width={5}
                            height={5}
                            borderRadius={5}
                          />
                          <TagLeftIcon as={MdOutlineDragIndicator} />
                          {func.name}
                          <IconButton
                            colorScheme="orange"
                            size="xs"
                            data-testid={`edit-function-${func.name}`}
                            aria-label="Edit function"
                            marginLeft="auto"
                            icon={<FaWrench />}
                            onClick={() => {
                              try {
                                editFunction(func.id);
                              } catch (e) {
                                toast({
                                  title: "Error",
                                  description:
                                    "Could not edit function, does it exist?",
                                  status: "error",
                                  duration: 9000,
                                  isClosable: true,
                                });
                              }
                            }}
                          />
                          <IconButton
                            colorScheme="red"
                            size="xs"
                            data-testid={`delete-function-${func.name}`}
                            aria-label="Delete function"
                            marginLeft={3}
                            icon={<CloseIcon />}
                            onClick={() => {
                              setFunctionToDelete(func.id);
                              functionDeleteModalOpen();
                            }}
                          />
                        </Tag>
                      );
                    })}
                  </Stack>
                  <InputGroup gap={3} mt={3}>
                    <Input
                      aria-label="Function creation name input"
                      placeholder="Enter a function name"
                      onKeyDown={(e) => {
                        // support pressing enter to add a response
                        if (e.key === "Enter") {
                          addFunction(currentFunction);
                        }
                      }}
                      onChange={(e) => {
                        setCurrentFunction(e.target.value);
                      }}
                    />
                    <IconButton
                      aria-label="Add function"
                      icon={<FaPlus />}
                      colorScheme="blue"
                      onClick={() => {
                        addFunction(currentFunction);
                      }}
                    />
                  </InputGroup>
                </Stack>
              </>
            ) : null}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Card>
  );
}

export default NodePalette;
